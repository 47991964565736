import React, { useState, useRef } from 'react';
import queryString from 'query-string';
import { removeParamFromURL, updateURL } from '../../../hooks';
import {
  OptionHeader,
  OptionTitle,
  OptionClear,
  Options,
  Option,
  OptionInput,
  OptionLabel
} from '../../global/Options';

function FilterRange(props) {
  const { location, history, scrollToMain } = props;
  const radioRef1 = useRef();
  const radioRef2 = useRef();
  const radioRef3 = useRef();

  const [optionSelected, setOptionSelected] = useState(false);

  const g = queryString.parse(location.search);

  const handleClear = e => {
    e.preventDefault();
    setOptionSelected(false);

    radioRef1.current.checked = false;
    radioRef2.current.checked = false;
    radioRef3.current.checked = false;

    const remove = removeParamFromURL('price', g);
    history.push('/' + remove);
  };

  const handleRange = e => {
    const newURL = updateURL(g, 'price', location.search, e.target.value);

    setOptionSelected(true);

    history.replace('/' + newURL);

    scrollToMain();
  };

  return (
    <>
      <OptionHeader>
        <OptionTitle>Price</OptionTitle>
        <OptionClear
          optionSelected={optionSelected}
          onClick={e => handleClear(e)}>
          Clear
        </OptionClear>
      </OptionHeader>
      <Options>
        <Option>
          <OptionInput
            type='radio'
            name='price'
            id='price1'
            ref={radioRef1}
            onClick={e => handleRange(e)}
            value='isLess-4000000'
          />
          <OptionLabel
            htmlFor='price1'
            isActive={
              g['price'] && g['price'] === 'isLess-4000000' ? true : false
            }>
            {'<$4m'}
          </OptionLabel>
        </Option>
        <Option>
          <OptionInput
            type='radio'
            name='price'
            id='price2'
            ref={radioRef2}
            onClick={e => handleRange(e)}
            value='4000000-15000000'
          />
          <OptionLabel
            htmlFor='price2'
            isActive={
              g['price'] && g['price'] === '4000000-15000000' ? true : false
            }>
            {'$4-15m'}
          </OptionLabel>
        </Option>
        <Option>
          <OptionInput
            type='radio'
            name='price'
            id='price3'
            ref={radioRef3}
            onClick={e => handleRange(e)}
            value='isGreater-15000000'
          />
          <OptionLabel
            htmlFor='price3'
            isActive={
              g['price'] && g['price'] === 'isGreater-15000000' ? true : false
            }>
            {'$15m+'}
          </OptionLabel>
        </Option>
      </Options>
    </>
  );
}

export default FilterRange;
