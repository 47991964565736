import React from 'react';
import styled, { css } from 'styled-components/macro';
import {
  colors,
  Wrapper,
  Section as section,
  SectionContent,
  SectionTitle,
  SectionText
} from '../../../helpers/styles';
import { Label, Value } from '../../global/Grid';
import PlaneMap from './PlaneMap';
import PlaneConnected from './PlaneConnected';

class PlaneInterior extends React.Component {
  render() {
    return (
      <Section altColor ref={this.props.interiorRef}>
        <Wrapper>
          <SectionContent>
            <PlaneMap
              details={this.props.details}
              setLightboxSrc={this.props.setLightboxSrc}
              lightbox={this.props.lightbox}
              setLightbox={this.props.setLightbox}
            />
          </SectionContent>
          <SectionContent>
            <PlaneConnected details={this.props.details} />
          </SectionContent>
        </Wrapper>
      </Section>
    );
  }
}

export default PlaneInterior;

const Section = styled(section)`
  background: ${colors.mineShaft};

  ${props =>
    props.altColor &&
    css`
      ${SectionTitle} {
        color: ${colors.bermudaGray};
      }

      ${SectionText} {
        color: ${colors.white};
      }

      ${Label} {
        color: ${colors.makara};
        font-weight: 800;
      }

      ${Value} {
        color: ${colors.white};
        font-weight: 200;
      }
    `}
`;
