import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { vars, colors, font, mediaQuery } from '../../../helpers/styles';
import { Grid as grid, GridCell, Value } from '../../global/Grid';
import { Button as button } from '../../global/Button';

function InventoryNoResults(props) {
  return (
    <Content>
      <Subtitle>
        There are no listings for your filtered&nbsp;selection.
      </Subtitle>
      <Grid>
        <GridCell hasValue altColor>
          <Value>We have plenty of other available options.</Value>
          <Button as={Link} to='/'>
            Clear all filters
          </Button>
        </GridCell>
        <GridCell hasValue altColor>
          <Value>Looking for something specific? Let us help.</Value>
          <Button as={Link} primary='true' to='/contact'>
            Contact Us
          </Button>
        </GridCell>
      </Grid>
    </Content>
  );
}

export default InventoryNoResults;

const Content = styled.div`
  padding: ${vars.spacing}px ${vars.gridGap}px;
`;

const Subtitle = styled.p`
  ${font({
    name: 'realist',
    size: '25px',
    weight: 400,
    color: colors.black,
    letterSpacing: '0.5px'
  })};
  margin: 0 0 ${vars.gridGap * 2}px;
  text-align: center;
`;

const Grid = styled(grid)`
  grid-template-columns: 1fr;

  @media ${mediaQuery.md} {
    grid-template-columns: 1fr 1fr;
  }

  ${GridCell} {
    background: ${colors.alabaster};
  }

  ${Value} {
    font-size: 14px;
    text-align: center;
    margin-bottom: ${vars.gridGap}px;
  }
`;

const Button = styled(button)`
  margin: auto auto ${vars.gridGap}px;
`;
