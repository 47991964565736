import React from 'react';
import styled, { css } from 'styled-components/macro';
import {
  vars,
  colors,
  font,
  mediaQuery,
  Wrapper,
  Section as section,
  SectionContent as sectionContent,
  SectionHeader,
  SectionTitle as sectionTitle,
  SectionText
} from '../../../helpers/styles';

function CompanyJourney(props) {
  return (
    <Section>
      <Wrapper>
        <SectionContent>
          <ImageSection>
            <ImageLabel>Business Aircraft Worldwide</ImageLabel>
            <Image wrappered src='../images/avpro-timeline.svg' />
          </ImageSection>
          <SectionTitle>Our Journey</SectionTitle>
          <FeatureGrid>
            <GridText>
              <strong>Sixty years ago</strong>, there were a total of fifteen
              business aircraft in the world. The majority were owned and
              operated by oil companies in the United States.
            </GridText>
            <FeatureDivider />
            <GridText>
              <strong>By 1991</strong>, when Don Bass, Chris Ellis, and Bob
              Rabbitt, incorporated Avpro, the number of business aircraft had
              soared to 7000.
            </GridText>
          </FeatureGrid>
          <Feature>
            <FeatureText>
              Aircraft became vital to the world economy and complicated to
              trade. This created a need for people who knew the value of
              aircraft and understood the market.
            </FeatureText>
          </Feature>
        </SectionContent>
        <SectionContent>
          <ImageSection>
            <Image src='../images/cloudsRight.jpg' />
          </ImageSection>
          <Grid featureFirst borderBottom>
            <FeatureText>
              Don, Chris, and Bob have stayed immersed in all aspects of the
              business from day one with a common focus on building
              relationships, understanding how markets work, and knowing what
              aircraft are worth.
            </FeatureText>
            <GridText>
              Avpro’s unique model changed the way business aircraft are bought
              and sold. Today, thirty years removed from Avpro’s founding, the
              industry has <strong>tripled in size</strong> and the total number
              of active business aircraft has ballooned to 20,000.{' '}
              <strong>
                Avpro brokers a significant share of these aircraft
              </strong>
              .
            </GridText>
          </Grid>
        </SectionContent>
        <SectionContent>
          <ImageSection>
            <Image src='../images/cloudsLeft.jpg' />
          </ImageSection>
          <FeatureText>
            Avpro’s success is the product of our people and our unique culture.
            Our company has produced many of the industry’s best analysts, sales
            and marketing executives, and transaction experts – and we’re not
            standing still. We are customer-focused, technology-driven, and
            opportunity oriented.
          </FeatureText>
        </SectionContent>
      </Wrapper>
    </Section>
  );
}

export default CompanyJourney;

const Section = styled(section)`
  background-color: ${colors.alabaster};
`;

const SectionContent = styled(sectionContent)`
  & + & {
    margin-top: ${vars.gridGap * 8}px;
  }
`;

const ImageSection = styled.div`
  margin: 0 0 ${vars.gridGap * 3}px;
`;

const ImageLabel = styled(SectionHeader)`
  margin: 0;
  color: ${colors.mineShaft};
`;

const Image = styled.img`
  object-fit: contain;
  width: ${props =>
    props.wrappered ? '100%' : `calc(100% + ${vars.gridGap * 3}px)`};
  transform: ${props =>
    props.wrappered ? 'none' : `translateX(-${vars.gridGap * 1.5}px)`};
  height: auto;
  display: block;

  @media ${mediaQuery.sm} {
    width: ${props =>
      props.wrappered ? '100%' : `calc(100% + ${vars.gridGap * 7}px)`};
    transform: ${props =>
      props.wrappered ? 'none' : `translateX(-${vars.gridGap * 3.5}px)`};
  }
`;

const SectionTitle = styled(sectionTitle)`
  text-align: center;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${vars.gridGap * 2}px;
  border-top: 1px solid ${colors.alto};
  border-bottom: 1px solid ${colors.alto};
  padding: ${vars.gridGap * 3}px 0;

  @media ${mediaQuery.md} {
    grid-template-columns: 1fr 1px 1fr;
  }
`;

const Feature = styled.div`
  border-top: 1px solid ${colors.alto};
  border-bottom: 1px solid ${colors.alto};
  padding: ${vars.gridGap * 3}px 0;

  ${FeatureGrid} + & {
    border-top: none;
  }
`;

const FeatureDivider = styled.div`
  display: none;

  @media ${mediaQuery.md} {
    display: block;
    width: 1px;
    height: 100%;
    box-shadow: 0 16px 0 ${colors.alto}, 0 -16px 0 ${colors.alto};
    background-color: ${colors.alto};
  }
`;

const FeatureText = styled.p`
  ${font({
    name: 'ivymode',
    responsiveSize: '24px, 32px',
    weight: 200,
    color: colors.mineShaft,
    lineHeight: 1.5
  })};
  margin: ${props => (props.marginBottom ? `0 0 ${vars.gridGap * 3}px` : 0)};

  & + & {
    margin-top: ${vars.gridGap * 2}px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${vars.gridGap * 2}px;
  margin-top: ${vars.gridGap * 3}px;

  @media ${mediaQuery.md} {
    grid-template-columns: ${props =>
      !props.featureFirst ? '1fr 1fr' : '65fr 35fr'};
  }

  ${props =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid ${colors.alto};
      padding-bottom: ${vars.gridGap * 3}px;
    `}
`;

const GridText = styled(SectionText)`
  font-weight: 400;
  margin: 0;
`;
