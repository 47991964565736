import styled, { css } from 'styled-components/macro';
import { darken } from 'polished';
import { vars, colors, font } from '../../helpers/styles';

export const OptionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${vars.gridGap / 2}px;
`;

export const OptionTitle = styled.p`
  ${font({
    name: 'realist',
    size: '12px',
    weight: 600,
    color: colors.tapa,
    transform: 'uppercase',
    letterSpacing: '1px'
  })};
  margin: 0;
`;

export const OptionClear = styled.button`
  ${font({
    name: 'realist',
    size: '12px',
    weight: 600,
    color: colors.mineShaft
  })};
  opacity: 0;
  visibility: hidden;
  transition: 250ms;
  background: transparent;
  border: none;
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    background: ${colors.alabaster};
  }

  ${props =>
    props.optionSelected &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

export const Options = styled.div`
  display: grid;
  grid-gap: 2px;
  grid-template-columns: repeat(3, 33.333%);
`;

export const Option = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OptionInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
`;

export const OptionLabel = styled.label`
  ${font({
    name: 'realist',
    size: '14px',
    weight: 600,
    color: colors.black,
    letterSpacing: '1px'
  })};
  background-color: ${colors.tea};
  padding: ${vars.gridGap}px ${vars.gridGap / 2}px;
  width: 100%;
  text-align: center;
  transition: 250ms ease;
  cursor: pointer;

  &:hover {
    background: ${darken(0.06, colors.tea)};
  }

  ${OptionInput}:focus + &,
  ${OptionInput}:checked + & {
    background-color: ${colors.makara};
    color: ${colors.white};
  }

  ${props =>
    props.isActive &&
    css`
      background-color: ${colors.makara};
      color: ${colors.white};
    `}
`;
