import React from 'react';
import styled, { css } from 'styled-components/macro';
import { rgba } from 'polished';
// import { storage } from '../../../base';
import { vars, colors, font, mediaQuery } from '../../../helpers/styles';
import { Icon, ArrowRight } from '../../Icons';
import _ from 'lodash';

class PlaneGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      images: _.get(this.props, 'details.gallery_images', []) || [],
      iframeImages: [],
    };
  }

  componentDidMount() {
    const iframeImages = 
      this.state.images.map((image) => (
          <img
            src={image}
            alt=''
            className='fslightbox-image'
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        ))

   
      this.setState({
        iframeImages
      });
  }

  componentDidUpdate() {
    this.props.setLightboxGallerySrc(this.state.iframeImages);
  }

  render() {
    if (this.state.iframeImages.length !== 0) {
      return (
        <>
          <Gallery
            data-gtm='gallery'
            onClick={(e) => {
              e.preventDefault();
              this.props.setLightboxGallery(!this.props.lightboxGallery);
            }}
            href='#'>
            <GalleryImageBox>
              <GalleryImage src={this.state.images[0]} alt='' />
            </GalleryImageBox>
            <GalleryImageBox>
              <GalleryImage src={this.state.images[1]} alt='' />
            </GalleryImageBox>
            <GalleryImageBox>
              <GalleryImage src={this.state.images[2]} alt='' />
            </GalleryImageBox>
            <GalleryScrim />
            <GalleryCta>
              <GalleryCtaIcon>
                <ArrowRight />
              </GalleryCtaIcon>
            </GalleryCta>
          </Gallery>
        </>
      );
    } else {
      return <Gallery empty />;
    }
  }
}

export default PlaneGallery;

const Gallery = styled.a`
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 1;
  position: relative;
  opacity: 0;
  height: 175px;
  margin: ${vars.gridGap * 2}px 0 0 ${vars.gridGap * 2}px;

  ${(props) =>
    !props.empty &&
    css`
      opacity: 1;
      &::before {
        content: '';
        height: 100%;
        width: 100px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(
          to right,
          ${rgba(colors.white, 0)} 0%,
          ${rgba(colors.white, 0.8)} 100%
        );
      }
    `}

  @media ${mediaQuery.lg} {
    height: 164px;
    width: 40%;
    margin: 0 0 0 auto;
  }
`;

const GalleryImageBox = styled.div`
  height: 100%;
  width: 85%;

  &:nth-of-type(2) {
    width: 15%;
  }

  &:nth-of-type(3) {
    display: none;
  }

  @media ${mediaQuery.xs} {
    width: 70%;

    &:nth-of-type(2) {
      width: 30%;
    }
  }

  @media ${mediaQuery.sm} {
    width: 33.333%;

    &:nth-of-type(2) {
      width: 33.333%;
    }

    &:nth-of-type(3) {
      display: block;
    }
  }

  @media ${mediaQuery.lg} {
    width: 45%;

    &:nth-of-type(2) {
      width: 45%;
    }

    &:nth-of-type(3) {
      width: 10%;
    }
  }

  @media ${mediaQuery.xl} {
    width: 33.333%;

    &:nth-of-type(2) {
      width: 33.333%;
    }

    &:nth-of-type(3) {
      width: 33.333%;
    }
  }
`;

const GalleryImage = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

const GalleryScrim = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(2px);
  color: ${colors.black};
  background-color: ${rgba(colors.makara, 0.8)};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 300ms ease-out;

  &::before {
    content: 'View Gallery';
    ${font({
      name: 'realist',
      size: '16px',
      weight: 800,
      color: colors.white,
      letterSpacing: '1px',
    })};
    opacity: 0;
    transform: translateY(${vars.gridGap}px);
    transition: 300ms ease-out;
  }

  ${Gallery}:hover & {
    opacity: 1;

    &::before {
      opacity: 1;
      transform: translateY(0);
      transition: 300ms ease-out 150ms;
    }
  }
`;

const GalleryCta = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 48px;
  width: 48px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 3px 6px ${rgba(colors.black, 0.15)};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 200ms;
  margin: auto ${vars.gridGap}px auto auto;

  &:hover {
    box-shadow: 0 5px 10px ${rgba(colors.black, 0.25)};
  }
`;

const GalleryCtaIcon = styled(Icon)`
  height: 16px;
  width: 16px;
`;
