import React from 'react';
import styled from 'styled-components/macro';
import ContactHero from './components/ContactHero';
import ContactInfo from './components/ContactInfo';
import Footer from '../Footer';

function Contact(props) {
  return (
    <>
      <ContactHero toggleScrolledNav={props.toggleScrolledNav} />
      <Content>
        <ContactInfo />
      </Content>
      <Footer />
    </>
  );
}

export default Contact;

const Content = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
