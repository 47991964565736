import React, { useEffect, useRef } from 'react';
import { CountUp } from 'countup.js';
import Observer from '@researchgate/react-intersection-observer';
import styled from 'styled-components/macro';
import {
  vars,
  colors,
  font,
  mediaQuery,
  Wrapper,
  Section as section
} from '../../../helpers/styles';

function ServicesHero(props) {
  const listingsRef = useRef();
  const contactRef = useRef();
  const transactionRef = useRef();
  const acquisitionsRef = useRef();

  // Only run on page load
  useEffect(() => {
    const countValue = (ref, startVal, endVal, duration, prefix, suffix) => {
      const countUp = new CountUp(ref.current, endVal, {
        duration: duration,
        startVal: startVal,
        prefix: prefix,
        suffix: suffix
      });

      if (!countUp.error) {
        countUp.start();
      } else {
        console.error(countUp.error);
      }
    };

    countValue(listingsRef, 1480, 1500, 6, '', '+');
    countValue(contactRef, 50, 70, 6, '', '%');
    countValue(transactionRef, 5, 25, 6, '$', 'B+');
    countValue(acquisitionsRef, 980, 1000, 6, '', '+');

    return;
  }, []);

  const handleIntersection = e => {
    props.toggleScrolledNav(!e.isIntersecting);
  };

  return (
    <Observer onChange={handleIntersection} rootMargin='0px 0px 0px'>
      <Hero>
        <HeroBackground />
        <Wrapper>
          <Section>
            <HeroContent>
              <HeroTitle>You Know to Call&nbsp;Avpro</HeroTitle>
              <HeroSubtitle>
                Delivering the smoothest ride to ownership, sales,
                and&nbsp;acquisition
              </HeroSubtitle>
              <HeroGraphic>
                <HeroGraphicPicture>
                  <source
                    srcSet='../images/hero-services-graphic.svg'
                    media='(min-width: 768px)'
                  />
                  <HeroGraphicSvg
                    src='../images/hero-services-graphic-mobile.svg'
                    alt="Radial circle graphic similar in style to an airplane's Heads Up Display, dissecting the company stats."
                  />
                </HeroGraphicPicture>
                <HeroStat>
                  <HeroStatValue ref={listingsRef} />
                  <HeroStatLabel>Successful Listings</HeroStatLabel>
                </HeroStat>
                <HeroStat>
                  <HeroStatValue ref={contactRef} />
                  <HeroStatLabel>
                    Buyers contact Avpro prior to an acquisition or sale
                  </HeroStatLabel>
                </HeroStat>
                <HeroStat>
                  <HeroStatValue ref={transactionRef} />
                  <HeroStatLabel>Total Transaction Value</HeroStatLabel>
                </HeroStat>
                <HeroStat>
                  <HeroStatValue ref={acquisitionsRef} />
                  <HeroStatLabel>Successful Acquisitions</HeroStatLabel>
                </HeroStat>
              </HeroGraphic>
            </HeroContent>
          </Section>
        </Wrapper>
      </Hero>
    </Observer>
  );
}

export default ServicesHero;

const Section = styled(section)`
  display: flex;
  padding: 40px 0 140px;
  background: transparent;

  @media ${mediaQuery.sm} {
    padding: 140px 0;
  }
`;

const Hero = styled.header`
  min-height: calc(100vh - ${vars.mobileNavBar});
  overflow: hidden;
  position: relative;

  @media ${mediaQuery.sm} {
    min-height: 100vh;
  }
`;

const HeroBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: url('../images/hero-services.jpg') ${colors.mineShaft} bottom
    center/cover no-repeat;
  display: flex;
`;

const HeroContent = styled.div`
  flex: 1 0 auto;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const HeroTitle = styled.h1`
  ${font({
    name: 'ivymode',
    responsiveSize: '40px, 57px',
    weight: 200,
    color: colors.alabaster,
    letterSpacing: '0.4px',
    lineHeight: 1.2
  })};
  margin: ${vars.gridGap}px 0;
`;

const HeroSubtitle = styled.h1`
  ${font({
    name: 'realist',
    responsiveSize: '20px, 25px',
    weight: 400,
    color: colors.alabaster,
    letterSpacing: '0.2px'
  })};
  margin: 0;
`;

const HeroGraphic = styled.div`
  margin-top: 10vh;
  position: relative;
  display: flex;
`;

const HeroGraphicPicture = styled.picture`
  display: flex;
  width: 100%;
  margin: 120px 0;

  @media ${mediaQuery.md} {
    margin: 0;
  }
`;

const HeroGraphicSvg = styled.img`
  width: 100%;
  height: 100%;
  margin: auto;
  object-fit: contain;

  @media ${mediaQuery.md} {
    width: 80%;
  }

  @media ${mediaQuery.lg} {
    width: 90%;
  }
`;

const HeroStat = styled.div`
  position: absolute;
  width: 110px;
  display: flex;
  flex-direction: column;

  @media ${mediaQuery.md} {
    width: 200px;
  }

  &:nth-of-type(1) {
    top: 0;
    left: 1%;

    @media ${mediaQuery.lg} {
      top: 5%;
    }
  }

  &:nth-of-type(2) {
    top: 0;
    right: 1%;
    align-items: flex-end;
    text-align: right;

    @media ${mediaQuery.lg} {
      top: 5%;
    }
  }

  &:nth-of-type(3) {
    top: 75%;
    left: 1%;

    @media ${mediaQuery.lg} {
      top: 78%;
    }
  }

  &:nth-of-type(4) {
    top: 75%;
    right: 1%;
    align-items: flex-end;
    text-align: right;

    @media ${mediaQuery.lg} {
      top: 78%;
    }
  }
`;

const HeroStatValue = styled.h1`
  ${font({
    name: 'ivy',
    responsiveSize: '34px, 57px',
    weight: 700,
    color: colors.alabaster,
    letterSpacing: '-0.3px'
  })};
  margin: 0;
`;

const HeroStatLabel = styled.p`
  ${font({
    name: 'realist',
    size: '1.4rem',
    weight: 700,
    color: colors.timberwolf,
    letterSpacing: '0.2px'
  })};
  margin: 0;
`;
