import React from 'react';
import styled from 'styled-components/macro';
import {
  vars,
  colors,
  font,
  Wrapper,
  Section as section,
  SectionText as sectionText
} from '../../../helpers/styles';
import { Button as button } from '../../global/Button';

function ServicesIntro(props) {
  const handleClick = e => {
    e.preventDefault();
    e.target.blur();

    window.scrollTo({
      top: props.servingRef.current.offsetTop - 65,
      behavior: 'smooth'
    });
  };

  return (
    <Section>
      <Wrapper>
        <Content>
          <Title>
            Every day, professionals across the business aviation industry
            contact Avpro because they trust our knowledge, performance, and
            integrity.
          </Title>
          <SectionText>
            Our business philosophy is to perform for our clients and to act
            always in our clients’ best interests. 
          </SectionText>
          <Button as={'button'} data-gtm='offer form' onClick={handleClick}>
            Learn More
          </Button>
        </Content>
      </Wrapper>
    </Section>
  );
}

export default ServicesIntro;

const Section = styled(section)`
  background: ${colors.white};
`;

const Content = styled.div`
  max-width: 675px;
`;

const Title = styled.h2`
  ${font({
    name: 'ivymode',
    responsiveSize: '28px, 32px',
    weight: 400,
    color: colors.bermudaGray
  })};
  margin: 0 0 ${vars.gridGap * 2}px;
`;

const SectionText = styled(sectionText)`
  font-weight: 400;
  margin: 0;
`;

const Button = styled(button)`
  margin-top: ${vars.gridGap * 2}px;
`;
