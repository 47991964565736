import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import Observer from '@researchgate/react-intersection-observer';
import styled, { css } from 'styled-components/macro';
import {
  vars,
  colors,
  mediaQuery,
  font,
  Wrapper,
  Section as section,
} from '../../../helpers/styles';

function InventoryHero(props) {
  const backgroundImgRef = useRef();
  const titleLeadRef = useRef();
  const titlePrimaryRef = useRef();
  const taglineHowRef = useRef();
  const taglineWhenRef = useRef();
  const taglineWhatRef = useRef();
  const subtitleRef = useRef();

  const handleIntersection = (e) => {
    props.toggleScrolledNav(!e.isIntersecting);
  };

  // Initial site load animation
  const initIntroAnimation = () => {
    const itemIn = {
      duration: 0.4,
      autoAlpha: 1,
      rotationX: '0deg',
    };

    const itemOut = {
      duration: 0.4,
      autoAlpha: 0,
      rotationX: '90deg',
      transformOrigin: '0% 0%',
      clearProps: 'all',
    };

    const tlTaglines = gsap
      .timeline({ delay: 0.5 })
      .to(taglineHowRef.current, itemIn)
      .to(taglineHowRef.current, itemOut, '+=1.6')
      .to(taglineWhenRef.current, itemIn, '-=0.3')
      .to(taglineWhenRef.current, itemOut, '+=1.6')
      .to(taglineWhatRef.current, itemIn, '-=0.3');

    gsap
      .timeline({ delay: 2 })
      .to(
        titleLeadRef.current,
        {
          duration: 0.4,
          autoAlpha: 1,
          ease: 'power1.in',
        },
        0
      )
      .add(tlTaglines)
      .to(
        titleLeadRef.current,
        {
          duration: 0.4,
          autoAlpha: 0,
          ease: 'power1.in',
        },
        '+=1.6'
      )
      .fromTo(
        titlePrimaryRef.current,
        {
          autoAlpha: 0,
          y: 15,
        },
        {
          duration: 1.5,
          autoAlpha: 1,
          y: 0,
          ease: 'power3.out',
        },
        '+=0.75'
      )
      .fromTo(
        subtitleRef.current,
        {
          autoAlpha: 0,
        },
        {
          duration: 0.75,
          autoAlpha: 1,
          ease: 'power1.in',
        },
        '+=0.5'
      );

    // Animate background
    gsap
      .timeline({ delay: 1 })
      .fromTo(
        backgroundImgRef.current,
        { scale: 1.15 },
        { duration: 20, scale: 1, ease: 'power2.inOut' }
      );
  };

  useEffect(() => {
    // Only run on initial site load
    if (!props.introAnimation) {
      // run intro animation once
      initIntroAnimation();

      // capture that animation has played once
      props.setIntroAnimation(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Observer onChange={handleIntersection} rootMargin='265px 0px 0px'>
      <Hero introAnimation={props.introAnimation}>
        <HeroBackground>
          <HeroBackgroundImage
            src='../images/hero.jpg'
            ref={backgroundImgRef}
          />
        </HeroBackground>
        <Wrapper>
          <Section>
            <HeroContent>
              <HeroTitleLead lead ref={titleLeadRef}>
                We know
                <HeroTaglines>
                  <HeroTagline ref={taglineHowRef}>How to Buy Low</HeroTagline>
                  <HeroTagline ref={taglineWhenRef}>
                    When to Sell High
                  </HeroTagline>
                  <HeroTagline ref={taglineWhatRef}>
                    What to Trade Now
                  </HeroTagline>
                </HeroTaglines>
              </HeroTitleLead>
              <HeroTitle primary ref={titlePrimaryRef}>
                Do You Know
                <br />
                What We Know?
              </HeroTitle>
              <HeroSubtitle ref={subtitleRef}>
                <strong>Buy</strong>, <strong>Sell</strong> &amp;{' '}
                <strong>Trade</strong> Business Aircraft, backed by{' '}
                <strong>Actionable&nbsp;Intelligence</strong>
              </HeroSubtitle>
            </HeroContent>
          </Section>
        </Wrapper>
      </Hero>
    </Observer>
  );
}

export default InventoryHero;

const Hero = styled.header`
  min-height: calc(60vh + 62px);
  display: flex;
  align-items: center;
  background-color: ${colors.timberwolf};
  position: relative;
  z-index: 1;

  ${(props) =>
    !props.introAnimation &&
    css`
      ${HeroBackground} {
        scale: 1.15;
      }
    `}

  ${(props) =>
    props.introAnimation &&
    css`
      ${HeroTitle}, ${HeroSubtitle} {
        opacity: 1;
      }

      ${HeroTitleLead} {
        opacity: 0;
      }
    `}
`;

const Section = styled(section)`
  display: flex;
  padding: 140px 0;
  background: transparent;
`;

const HeroBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
`;

const HeroBackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center left;
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const HeroTitle = styled.div`
  ${font({
    name: 'ivymode',
    responsiveSize: '42px, 57px',
    weight: 400,
    color: colors.black,
    letterSpacing: '0.4px',
  })};
  display: flex;
  opacity: 0;
  margin: 0 0 ${vars.gridGap * 3}px;
  text-align: center;
`;

const HeroTitleLead = styled(HeroTitle)`
  position: absolute;
  flex-direction: column;
  ${font({
    responsiveSize: '30px, 57px',
  })};
  text-align: center;
  margin: 0 auto;

  @media ${mediaQuery.md} {
    white-space: nowrap;
    flex-direction: row;
    text-align: left;
  }
`;

const HeroTaglines = styled.ul`
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;

  &::after {
    content: 'What to Trade Now';
    display: block;
    font-weight: 600;
    margin-left: 12px;
    opacity: 0;
  }

  @media ${mediaQuery.md} {
    margin-left: 12px;
  }
`;

const HeroTagline = styled.li`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-weight: 600;
  transform: rotateX(-90deg);
  transform-origin: 100% 100% 0;
  opacity: 0;
`;

const HeroSubtitle = styled.p`
  ${font({
    name: 'realist',
    responsiveSize: '18px 25px',
    weight: 400,
    color: colors.black,
    letterSpacing: '0.5px',
  })};
  position: relative;
  margin: 0;
  text-align: center;
  opacity: 0;
`;
