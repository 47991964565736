import React from 'react';
import styled from 'styled-components/macro';
import {
  vars,
  colors,
  font,
  Wrapper,
  Section as section,
  SectionText as sectionText
} from '../../../helpers/styles';

function CompanyIntro(props) {
  return (
    <Section>
      <Wrapper>
        <Content>
          <Title>
            Our clients refer to our services as transaction insurance that
            saves them millions through superior market intelligence and
            unrivaled execution – in each and every transaction.
          </Title>
          <SectionText>
            We will sell your aircraft for the highest market price in the
            shortest time – and we will buy the right aircraft at the correct
            price, with the most favorable terms and conditions.
          </SectionText>
        </Content>
      </Wrapper>
    </Section>
  );
}

export default CompanyIntro;

const Section = styled(section)`
  background: ${colors.alabaster};
`;

const Content = styled.div`
  max-width: 675px;
`;

const Title = styled.h2`
  ${font({
    name: 'ivymode',
    responsiveSize: '28px, 32px',
    weight: 400,
    color: colors.makara
  })};
  margin: 0 0 ${vars.gridGap * 2}px;
`;

const SectionText = styled(sectionText)`
  font-weight: 400;
  color: ${colors.gray};
`;
