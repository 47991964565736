import React from 'react';
import styled, { css } from 'styled-components/macro';
import { vars, colors, font, mediaQuery } from '../../helpers/styles';

export const GridDataCell = props => (
  <GridCell
    spanCol={props.spanCol ? props.spanCol : false}
    spanRow={props.spanRow ? props.spanRow : false}
    hasValue={props.value}
    noPaddingLeft={props.noPaddingLeft}
    altColor={props.altColor}>
    <Label>{props.label}</Label>
    <Value>{props.value ? props.value : '-'}</Value>
  </GridCell>
);

export const GridContentCell = props => (
  <GridCellContent
    spanCol={props.spanCol ? props.spanCol : false}
    spanRow={props.spanRow ? props.spanRow : false}
    hasValue={props.value}
    altColor={props.altColor}>
    <Label>{props.label}</Label>
    <Value>{props.value ? props.value : '-'}</Value>
  </GridCellContent>
);

export const Grid = styled.div`
  display: grid;
  background: ${props =>
    props.noPadding ? 'transparent' : colors.alto}; /* creates grid outline */
  grid-gap: 1px;
  padding: ${props => (props.noPadding ? '0' : '1px 0')};

  /* gridLayout can be 'full', 'half', 'thirds', 'quarters' */
  ${props =>
    props.gridLayout === 'full' &&
    css`
      grid-template-columns: 1fr;
    `}

  ${props =>
    props.gridLayout === 'half' &&
    css`
      grid-template-columns: repeat(2, 50%);
    `}

  ${props =>
    props.gridLayout === 'thirds' &&
    css`
      grid-template-columns: repeat(2, 50%);

      @media ${mediaQuery.md} {
        grid-template-columns: repeat(3, 33.333%);
      }
    `}

  ${props =>
    props.gridLayout === 'quarters' &&
    css`
      grid-template-columns: repeat(4, 25%);
    `}

  ${props =>
    props.gridLayout &&
    (props.gridLayout !== 'half' &&
      props.gridLayout !== 'quarters' &&
      props.gridLayout !== 'thirds') &&
    css`
      grid-template-columns: ${props.gridLayout};
    `}
`;

export const GridCell = styled.div`
  display: flex;
  flex-direction: column;
  border-top: none;
  border-bottom: none;
  background: ${props =>
    props.altColor ? colors.mineShaft : colors.alabaster};
  padding: ${vars.gridGap}px;
  grid-column: ${props => (props.spanCol ? `span ${props.spanCol}` : 'auto')};
  grid-row: ${props => (props.spanRow ? `span ${props.spanRow}` : 'auto')};

  @media ${mediaQuery.md} {
    padding: ${props =>
      props.noPaddingLeft
        ? `${vars.gridGap}px ${vars.gridGap}px ${vars.gridGap}px 0`
        : props.noPadding
        ? '0px'
        : `${vars.gridGap}px`};

    ${props =>
      props.rightAlign &&
      css`
        padding-right: 0;
        text-align: right;
      `}
  }
`;

export const GridCellContent = styled.div`
  border-top: none;
  border-bottom: none;
  background: ${props =>
    props.altColor ? colors.mineShaft : colors.alabaster};
  padding: ${vars.gridGap * 1.5}px;
  grid-column: ${props => (props.spanCol ? `span ${props.spanCol}` : 'auto')};
  grid-row: ${props => (props.spanRow ? `span ${props.spanRow}` : 'auto')};
`;

export const GridContent = styled.div`
  width: 100%;
`;

export const Value = styled.p`
  ${font({
    name: 'realist',
    responsiveSize: '16px, 20px',
    weight: 400,
    color: colors.black
  })};
  margin: 0;
`;

export const Label = styled.p`
  ${font({
    name: 'realist',
    responsiveSize: '10px, 12px',
    weight: 600,
    color: colors.makara,
    letterSpacing: '1.1px',
    transform: 'uppercase'
  })};
  margin: 0 0 ${vars.gridGap / 4}px;

  ${Value} + & {
    margin-top: ${vars.gridGap}px;
  }
`;
