import React from 'react';
import styled from 'styled-components/macro';
import {
  vars,
  colors,
  font,
  mediaQuery,
  Wrapper,
  Section as section
} from '../helpers/styles';
import { Link } from 'react-router-dom';
import { Grid as grid, GridCell, Value } from './global/Grid';
import { Button as button } from './global/Button';

function NotFound(props) {
  return (
    <Hero>
      <HeroBackground>
        <HeroBackgroundImage src='../images/hero-contact.jpg' />
      </HeroBackground>
      <Wrapper>
        <Section>
          <HeroContent>
            <HeroTitle>404 Error</HeroTitle>
            <HeroSubtitle>
              The page you requested could not be found.
            </HeroSubtitle>
            <Grid>
              <GridCell hasValue altColor>
                <Value>Take a look through our other inventory.</Value>
                <Button as={Link} to='/'>
                  Take me Home
                </Button>
              </GridCell>
              <GridCell hasValue altColor>
                <Value>Looking for something specific? Let us help.</Value>
                <Button as={Link} primary='true' to='/contact'>
                  Contact Us
                </Button>
              </GridCell>
            </Grid>
          </HeroContent>
        </Section>
      </Wrapper>
    </Hero>
  );
}

export default NotFound;

const Section = styled(section)`
  display: flex;
  background: transparent;
  padding: 140px 0;
`;

const Hero = styled.header`
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
`;

const HeroBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: ${colors.timberwolf};
`;

const HeroBackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
`;

const HeroContent = styled.div`
  flex: 1 0 auto;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  padding: ${vars.spacing / 4}px;

  @media ${mediaQuery.sm} {
    padding: ${vars.spacing / 2}px;
  }

  @media ${mediaQuery.lg} {
    padding: ${vars.spacing}px;
  }
`;

const HeroTitle = styled.h1`
  ${font({
    name: 'ivymode',
    responsiveSize: '40px, 57px',
    weight: 200,
    color: colors.black,
    letterSpacing: '0.4px'
  })};
  margin: 0 0 ${vars.gridGap}px;
  text-align: center;
`;

const HeroSubtitle = styled.p`
  ${font({
    name: 'realist',
    size: '25px',
    weight: 400,
    color: colors.black,
    letterSpacing: '0.5px'
  })};
  margin: 0 0 ${vars.gridGap * 2}px;
  text-align: center;
`;

const Grid = styled(grid)`
  grid-template-columns: 1fr;

  @media ${mediaQuery.md} {
    grid-template-columns: 1fr 1fr;
  }

  ${GridCell} {
    background: ${colors.white};
  }

  ${Value} {
    font-size: 14px;
    text-align: center;
    margin-bottom: ${vars.gridGap}px;
  }
`;

const Button = styled(button)`
  margin: auto auto ${vars.gridGap}px;
`;
