import 'intersection-observer';
import React from 'react';
import queryString from 'query-string';
import styled, { css } from 'styled-components/macro';
import { vars, colors, font, mediaQuery } from '../../../helpers/styles';
import FilterCabinClass from './FilterCabinClass';
import FilterManufacturer from './FilterManufacturer';
import FilterYear from './FilterYear';
import FilterRange from './FilterRange';
import FilterAirframeHours from './FilterAirframeHours';
import FilterPrice from './FilterPrice';
import { removeParamFromURL } from '../../../hooks';
import { Icon, ChevronRight, Close } from '../../Icons';

function InventorySidebar(props) {
  const g = queryString.parse(props.location.search);

  const handleCloseClick = (e) => {
    props.setFilterState(false);
    props.toggleLockScroll(false);
  };

  const handleClick = (e) => {
    e.preventDefault();

    // Define sidebar parameters to remove
    const params = [
      'cabin_class',
      'year_model',
      'manufacturer',
      'flight_range',
      'airframe_total_time',
      'price',
    ];

    const remove = removeParamFromURL(params, g);

    props.history.push('/' + remove);

    props.scrollToMain();
  };

  const cabins = [
    {
      title: 'Long Range',
      text: 'Long range and accommodation for large groups up to 14.',
    },
    {
      title: 'Mid/Super Mid',
      text: 'Comfortable for longer commutes and mid-size groups.',
    },
    {
      title: 'Light',
      text: 'Perfect for groups up to 6 and landing at the smallest airports.',
    },
  ];

  return (
    <>
      <SidebarScrim filterState={props.filterState} />
      <Sidebar scrolledNav={props.scrolledNav} filterState={props.filterState}>
        <CloseBtn onClick={handleCloseClick}>
          <CloseBtnIconBox>
            <Close />
          </CloseBtnIconBox>
        </CloseBtn>
        {!g['cabin_class'] ? (
          <FilterCabinClass
            location={props.location}
            history={props.history}
            planes={props.planes}
            scrollToMain={props.scrollToMain}
            cabins={cabins}
          />
        ) : null}
        {g['cabin_class'] ? (
          <>
            {cabins.map((cabin) => {
              if (cabin.title === g['cabin_class']) {
                return (
                  <CabinInfo
                    key={`cabinInfo-${cabin.title
                      .replace(' ', '-')
                      .toLowerCase()}`}>
                    <Back onClick={handleClick}>
                      <BackIconBox>
                        <BackIcon>
                          <ChevronRight />
                        </BackIcon>
                      </BackIconBox>{' '}
                      Back To Cabin Class
                    </Back>
                    <CabinTitle>{cabin.title}</CabinTitle>
                    <CabinText>{cabin.text}</CabinText>
                  </CabinInfo>
                );
              }
              return null;
            })}
            <SidebarFilterBox>
              <FilterManufacturer
                history={props.history}
                location={props.location}
                planes={props.planes}
                scrollToMain={props.scrollToMain}
              />
            </SidebarFilterBox>
            <SidebarFilterBox>
              <FilterYear
                history={props.history}
                location={props.location}
                planes={props.planes}
                scrollToMain={props.scrollToMain}
              />
            </SidebarFilterBox>
            <SidebarFilterBox>
              <FilterRange
                history={props.history}
                location={props.location}
                scrollToMain={props.scrollToMain}
              />
            </SidebarFilterBox>
            <SidebarFilterBox>
              <FilterAirframeHours
                history={props.history}
                location={props.location}
                scrollToMain={props.scrollToMain}
              />
            </SidebarFilterBox>
            <SidebarFilterBox>
              <FilterPrice
                history={props.history}
                location={props.location}
                scrollToMain={props.scrollToMain}
              />
            </SidebarFilterBox>
          </>
        ) : null}
      </Sidebar>
    </>
  );
}

export default InventorySidebar;

const Sidebar = styled.div`
  top: ${vars.mobileNavBar};
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${colors.timberwolf};
  overflow: auto;
  padding: 0;
  height: calc(100vh - ${vars.mobileNavBar});
  transition: transform 400ms ease-out;
  z-index: 6;
  position: fixed;
  transform: translateX(-100%);
  will-change: transform, top, height;

  ${(props) =>
    props.filterState &&
    css`
      transform: translateX(0);
    `}

  @media (min-width: 400px) {
    max-width: 320px;
  }

  @media ${mediaQuery.sm} {
    max-width: 340px;
  }

  @media ${mediaQuery.lg} {
    top: 0;
    height: 100vh;
    transition: 600ms ease;
    transform: translateX(0);
    position: sticky;

    ${(props) =>
      props.scrolledNav &&
      css`
        top: 65px;
        height: calc(100vh - 65px);
      `}
  }
`;

const SidebarFilterBox = styled.div`
  padding: ${vars.gridGap * 1.5}px ${vars.gridGap * 2}px;
  border-top: 1px solid ${colors.tea};

  &:first-of-type {
    padding: ${vars.gridGap * 2}px ${vars.gridGap * 2}px ${vars.gridGap * 1.5}px;
  }
`;

const CabinInfo = styled(SidebarFilterBox)`
  position: relative;
  padding: ${vars.gridGap * 2.5}px;

  &::after {
    opacity: 0.2;
    position: absolute;
    top: ${vars.gridGap * 6}px;
    right: ${vars.gridGap * 2.5}px;
    content: '';
    display: block;
    width: 68px;
    height: 38px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 106.58 63.9'%3E %3Cpath d='M43.4,17.77,40,3.15l5.39-.33L63.41,17.68l1.75-2.14L46.27,0,36.53.59,40.72,18.4Z' style='fill: %238c8070'/%3E %3Cpath d='M93.5,20.21l-74,4.92-7.87-6.75L0,18.3,11.62,38.64l11.24,3.65,22.86-1L50,63.15l9,.75L69.74,39.7l34.11-2,2.73-8.4ZM101.8,35l-33.9,2L57.24,61l-5-.42L47,33.49,44.31,34l.88,4.52-21.95,1-9.76-3.17L4.77,21.09l5.81,0,8,6.83L92.72,23l10.6,7.33Z' style='fill: %238c8070'/%3E %3C/svg%3E ")
      center center/contain no-repeat;
  }
`;

const CabinTitle = styled.p`
  ${font({
    name: 'realist',
    size: '25px',
    weight: 400,
    color: colors.mineShaft,
    letterSpacing: '0.5px',
  })};
  margin: 0 0 ${vars.gridGap / 2}px;
`;

const CabinText = styled.p`
  ${font({
    name: 'realist',
    size: '14px',
    weight: 400,
    color: '#787672',
    letterSpacing: '0.5px',
  })};
  margin: 0;
`;

const BackIconBox = styled.span`
  height: 25px;
  width: 25px;
  border-radius: 50px;
  background-color: ${colors.makara};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 400ms;
  margin-right: ${vars.gridGap / 2}px;
`;

const BackIcon = styled(Icon)`
  height: 10px;
  width: 7px;
  transform: rotateY(180deg);
  fill: ${colors.white};
`;

const Back = styled.button`
  ${font({
    name: 'realist',
    size: '16px',
    weight: 400,
    color: colors.mineShaft,
    letterSpacing: '0.5px',
    transform: 'uppercase',
  })};
  background: transparent;
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  margin-bottom: ${vars.gridGap * 2}px;
  cursor: pointer;

  &:hover {
    ${BackIconBox} {
      transform: translateX(-${vars.gridGap / 4}px);
    }
  }
`;

const SidebarScrim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(30, 30, 30, 0.9);
  visibility: hidden;
  opacity: 0;
  transition: 250ms;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  display: none;

  ${(props) =>
    props.filterState &&
    css`
      visibility: visible;
      opacity: 1;
    `}

  @media ${mediaQuery.lg} {
    display: none;
  }
`;

const CloseBtn = styled.button`
  background: transparent;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: ${vars.gridGap}px;
  top: ${vars.gridGap}px;
  padding: 0;
  border: none;
  margin-bottom: ${vars.gridGap * 2}px;
  cursor: pointer;
  position: absolute;
  z-index: 1;

  @media (min-width: 400px) {
    display: none;
  }
`;

const CloseBtnIconBox = styled(Icon)`
  height: 20px;
  width: 20px;
`;
