import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import ServicesHero from './components/ServicesHero';
import ServicesIntro from './components/ServicesIntro';
import ServicesServingYou from './components/ServicesServingYou';
import ServicesInterstitial from './components/ServicesInterstitial';
import ServicesServices from './components/ServicesServices';
import ServicesMarketAnalysis from './components/ServicesMarketAnalysis';
import ServicesMarketAd from './components/ServicesMarketAd';
import ServicesTransactionDoc from './components/ServicesTransactionDoc';
import ServicesCTA from './components/ServicesCTA';
import Footer from '../Footer';

function Services(props) {
  const servingRef = useRef();

  return (
    <>
      <ServicesHero toggleScrolledNav={props.toggleScrolledNav} />
      <Content>
        <ServicesIntro servingRef={servingRef} />
        <ServicesServingYou servingRef={servingRef} />
        <ServicesInterstitial
          sourceImage='../images/orangeCloudsRight.jpg'
          clearBottom
          clearTop
        />
        <ServicesServices />
        <ServicesInterstitial
          sourceImage='../images/avJet.jpg'
          mobilePosition='85%'
          clearBottom
          clearTop
        />
        <ServicesMarketAnalysis />
        <ServicesMarketAd />
        <ServicesTransactionDoc />
        {/* <CompanyIntro />
        <CompanyKnowledge />
        <CompanyPerformance />
        <CompanyIntegrity />
        <CompanyJourney /> */}
      </Content>
      <ServicesCTA />
      <Footer />
    </>
  );
}

export default Services;

const Content = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
