import React from 'react';
import styled from 'styled-components/macro';
import { colors } from '../../../helpers/styles';
import { Section } from '../../../helpers/styles';

function PlaneInterstitial(props) {
  return (
    <Section clearBottom={props.clearBottom} clearTop={props.clearTop}>
      <Interstitial src={props.sourceImage} alt='' />
    </Section>
  );
}

export default PlaneInterstitial;

const Interstitial = styled.img`
  object-fit: cover;
  width: 100%;
  height: 65vh;
  max-height: 1000px;
  display: block;
  background: ${colors.alto};
`;
