import React from 'react';
import styled from 'styled-components/macro';
import {
  vars,
  colors,
  font,
  mediaQuery,
  Wrapper,
  Section as section
} from '../../../helpers/styles';
import { Grid, GridCell, Label, Value } from '../../global/Grid';

function ContactInfo(props) {
  return (
    <Section>
      <Wrapper>
        <Content>
          <Title>
            We’ve earned an impressive record by doing one thing very well —
            providing superior customer service.
          </Title>
          <Info>
            <InfoCell>
              <InfoLabel>Location</InfoLabel>
              <InfoValue>
                900 Bestgate Road, Suite 412
                <br />
                Annapolis, Maryland 21401
              </InfoValue>
            </InfoCell>
            <InfoCell>
              <InfoLabel>Phone</InfoLabel>
              <InfoLink href='tel:14105731515'>+1.410.573.1515</InfoLink>
            </InfoCell>
            <InfoCell>
              <InfoLabel>Email</InfoLabel>
              <InfoLink href='mailto:info@avprojets.com'>
                info@avprojets.com
              </InfoLink>
            </InfoCell>
          </Info>
        </Content>
      </Wrapper>
    </Section>
  );
}

export default ContactInfo;

const Section = styled(section)`
  background: ${colors.alabaster};
  padding: ${vars.spacing}px 0;

  @media ${mediaQuery.md} {
    padding: ${vars.spacing * 1.5}px 0;
  }
`;

const Content = styled.div`
  max-width: 800px;
`;

const Title = styled.h2`
  ${font({
    name: 'ivymode',
    responsiveSize: '28px, 32px',
    weight: 400,
    color: colors.makara
  })};
  margin: 0 0 ${vars.gridGap * 3}px;
`;

const Info = styled(Grid)`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0;
  grid-gap: ${vars.gridGap * 2}px;
  background: transparent;

  @media ${mediaQuery.sm} {
    grid-template-columns: 1.5fr 1fr;
  }
`;

const InfoCell = styled(GridCell)`
  padding: 0;

  @media ${mediaQuery.md} {
    padding: 0;
  }
`;

const InfoLabel = styled(Label)``;

const InfoValue = styled(Value)`
  font-weight: 200;
`;

const InfoLink = styled.a`
  ${font({
    name: 'realist',
    responsiveSize: '16px, 20px',
    weight: 400,
    color: colors.blueGem
  })};
  margin: 0;
  align-self: flex-start;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
