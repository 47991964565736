import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import PlaneHero from './components/PlaneHero';
import PlaneInterstitial from './components/PlaneInterstitial';
import HiddenImage from './components/HiddenImage';
import PlaneMetricsPerformance from './components/PlaneMetricsPerformance';
import PlaneInterior from './components/PlaneInterior';
import PlaneExterior from './components/PlaneExterior';
import PlaneAirframeEngines from './components/PlaneAirframeEngines';
import PlaneCTA from './components/PlaneCTA';
import Footer from '../Footer';
import PlaneSidebar from './components/PlaneSidebar';
import PlaneHighlights from './components/PlaneHighlights';
import OfferModal from './components/OfferModal';
import { vars, colors, mediaQuery } from '../../helpers/styles';
import FsLightbox from 'fslightbox-react';
 
const zoneImagesTags = ['first_zone_image', 'second_zone_image', 'third_zone_image', 'fourth_zone_image', 'fifth_zone_image']

function Plane(props) {
  const [lightbox, setLightbox] = useState(false);
  const [lightboxSrc, setLightboxSrc] = useState('');
  const [lightboxGallery, setLightboxGallery] = useState(false);
  const [offerModal, setOfferModal] = useState(false);
  const [lightboxGallerySrc, setLightboxGallerySrc] = useState([]);

  const highlightRef = useRef();
  const metricsRef = useRef();
  const interiorRef = useRef();
  const exteriorRef = useRef();
  const airframeRef = useRef();

  const refProps = {
    highlightRef,
    metricsRef,
    interiorRef,
    exteriorRef,
    airframeRef
  };

  const details = props.details;

  return (
    <>
      <PlaneHero
        details={details}
        toggleScrolledNav={props.toggleScrolledNav}
        setLightboxSrc={setLightboxSrc}
        lightbox={lightbox}
        setLightbox={setLightbox}
        setLightboxGallerySrc={setLightboxGallerySrc}
        lightboxGallerySrc={lightboxGallerySrc}
        lightboxGallery={lightboxGallery}
        setLightboxGallery={setLightboxGallery}
        highlightRef={highlightRef}
        metricsRef={metricsRef}
        setOfferModal={setOfferModal}
      />
      <Content>
        <PlaneContent>
          <PlaneHighlights details={details} highlightRef={highlightRef} />
          <PlaneMetricsPerformance details={details} metricsRef={metricsRef} />
          <PlaneInterstitial
            sourceImage={details['interior']}
            clearTop
            clearBottom
          />
          {/* need to preload images in the browswer cache or else the (non gallery) FsLightbox won't display the images properly */}
          {zoneImagesTags.map(imageTag => details[imageTag] ? <HiddenImage
            sourceImage={details[imageTag]}
            key={imageTag}
          /> : null
          )}
          
          <PlaneInterior
            details={details}
            interiorRef={interiorRef}
            setLightboxSrc={setLightboxSrc}
            lightbox={lightbox}
            setLightbox={setLightbox}
          />
          <PlaneExterior
            details={details}
            exteriorRef={exteriorRef}
            setLightboxSrc={setLightboxSrc}
            lightbox={lightbox}
            setLightbox={setLightbox}
          />
          <PlaneAirframeEngines details={details} airframeRef={airframeRef} />
          <PlaneInterstitial
            sourceImage={details['exterior2']}
            clearTop
            clearBottom
          />
        </PlaneContent>
        <PlaneSidebar
          details={details}
          setOfferModal={setOfferModal}
          {...refProps}
        />
      </Content>
      <PlaneCTA details={details} setOfferModal={setOfferModal} />
      <Footer />
      {/**
       * I need to use custom sources for the images
       * since the default sources option was not working
       *
       * ¯\_(ツ)_/¯
       */}
      {/* normal lightbox */}
      <FsLightbox
        toggler={lightbox}
        customSources={[lightboxSrc]}
        customSourcesGlobalMaxDimensions={{ width: 1920, height: 1080 }}
      />
      {/* gallery lightbox */}
      {lightboxGallerySrc.length !== 0 ? (
        <FsLightbox
          toggler={lightboxGallery}
          customSources={lightboxGallerySrc}
          key={'planeGallery'}
          types={['image']}
          customSourcesGlobalMaxDimensions={{ width: 1920, height: 1080 }}
        />
      ) : null}
      <OfferModal
        offerModal={offerModal}
        setOfferModal={setOfferModal}
        toggleLockScroll={props.toggleLockScroll}
        details={details}
      />
    </>
  );
}

export default Plane;

const Content = styled.main`
  display: flex;
  background-color: ${colors.alabaster};
  width: 100%;
`;

const PlaneContent = styled.div`
  flex-direction: column;
  width: 100%;

  @media ${mediaQuery.lg} {
    width: calc(100% - ${vars.sidebar});
  }
`;
