import React from 'react';
import styled from 'styled-components/macro';
import {
  vars,
  colors,
  Wrapper,
  mediaQuery,
  Section as section,
  SectionTitle as sectionTitle,
  SectionText as sectionText
} from '../../../helpers/styles';
import {
  Grid as grid,
  GridDataCell,
  GridCell,
  Label,
  Value
} from '../../global/Grid';

function ServicesMarketAd(props) {
  return (
    <Section clearTop>
      <Wrapper>
        <Content>
          <SectionTitle>Marketing &amp; Advertising</SectionTitle>
          <SectionText>
            Our Marketing and Advertising Team provides maximum effective
            exposure to buyers and sellers ready to move. In support of overall
            sales and pricing strategies, we provide:
          </SectionText>
          <Grid>
            <GridDataCell
              label='Online Marketing'
              value='Web, mobile, and social media campaigns, including advanced analytics'
            />
            <GridDataCell
              label='Aircraft Specs'
              value='Dedicated web listing and detailed aircraft specifications optimized for all platforms'
            />
            <GridDataCell
              label='Showcase'
              value='Professional aviation photography, videography, and
              web-based virtual reality tours'
            />
            <GridDataCell
              label='Advertising'
              value='Avpro proprietary channels and premier advertising partnerships with top national and international media organizations'
            />
            <GridDataCell
              label='Email Marketing'
              value='Direct mail and customized email broadcast campaigns'
            />
          </Grid>
        </Content>
      </Wrapper>
    </Section>
  );
}

export default ServicesMarketAd;

const Section = styled(section)`
  background: ${colors.alabaster};
`;

const Content = styled.div``;

const SectionTitle = styled(sectionTitle)`
  color: ${colors.bermudaGray};
`;

const SectionText = styled(sectionText)`
  font-weight: 400;
`;

const Grid = styled(grid)`
  margin-top: ${vars.gridGap * 2}px;

  @media ${mediaQuery.sm} {
    padding: 1px;
  }

  ${GridCell} {
    padding: ${vars.gridGap}px ${vars.gridGap / 2}px;

    @media ${mediaQuery.sm} {
      padding: ${vars.gridGap}px ${vars.gridGap * 2}px;
    }
  }

  ${Label} {
    font-weight: 800;
  }

  ${Value} {
    font-weight: 200;
  }
`;
