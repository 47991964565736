import React from 'react';
import styled from 'styled-components/macro';
import {
  vars,
  colors,
  font,
  mediaQuery,
  Wrapper,
  Section as section,
  SectionText
} from '../../../helpers/styles';

function CompanyPerformance(props) {
  return (
    <Section>
      <Wrapper>
        <Title>Performance</Title>
        <Feature>
          <FeatureText>
            For our clients – we sell aircraft at or above market levels and we
            acquire aircraft below fair market value. Our clients have relied on
            our hedging strategies to successfully navigate major economic
            cycles, including severe market downturns.
          </FeatureText>
        </Feature>
        <Grid>
          <GridText>
            We’re trusted by high net worth individuals, corporate executives,
            Original Equipment manufacturers, aircraft management companies,
            aviation service providers, leading aviation attorneys, and the
            largest global financial institutions. We’re{' '}
            <strong>the most trusted partner</strong> to execute complex deals
            and our transaction team is without equal in the industry.
          </GridText>
          <GridText>
            We have access to <strong>diversified credit lines</strong>{' '}
            exceeding $100 million that we can deploy to facilitate aircraft
            trade-ins on behalf of our clients.
          </GridText>
        </Grid>
      </Wrapper>
    </Section>
  );
}

export default CompanyPerformance;

const Section = styled(section)`
  background-color: ${colors.alabaster};
`;

const Title = styled.h1`
  ${font({
    name: 'ivymode',
    responsiveSize: '32px, 57px',
    weight: 200,
    color: colors.makara,
    letterSpacing: '-0.3px'
  })};
  margin: 0 0 ${vars.gridGap}px;
`;

const Feature = styled.div`
  border-top: 1px solid ${colors.alto};
  border-bottom: 1px solid ${colors.alto};
  padding: ${vars.gridGap * 3}px 0;
`;

const FeatureText = styled.p`
  ${font({
    name: 'ivymode',
    responsiveSize: '24px, 32px',
    weight: 200,
    color: colors.mineShaft,
    lineHeight: 1.5
  })};
  margin: 0;

  & + & {
    margin-top: ${vars.gridGap * 2}px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${vars.gridGap * 2}px;
  margin-top: ${vars.gridGap * 3}px;

  @media ${mediaQuery.md} {
    grid-template-columns: 1fr 1fr;
  }
`;

const GridText = styled(SectionText)`
  margin: 0;
  font-weight: 400;
`;
