import React from 'react';
import Plane from './plane/Plane';
import NotFound from './NotFound';

export default function PlaneDetails(props) {
  const aircraft = props.match.params.aircraft.toLowerCase();
  const planes = Array.from(props.planes);

  if (planes.length > 0) {
    for (let i = 0; i < planes.length; i++) {
      let name = `${planes[i]['manufacturer']} ${planes[i]['model']} SN:${planes[i]['serial_number']}`;
      if (aircraft === name.replace(/ /g, '-').toLowerCase()) {
        const planeDetails = planes[i];

        return (
          <Plane
            details={planeDetails}
            scrolledNav={props.scrolledNav}
            toggleLockScroll={props.toggleLockScroll}
            toggleScrolledNav={props.toggleScrolledNav}
          />
        );
      }
    }
    return <NotFound />;
  }
  return null;
}
