import React from 'react';
import styled from 'styled-components/macro';
import {
  vars,
  colors,
  font,
  Wrapper,
  Section as section,
  SectionHeader,
  SectionTitle,
  SectionText
} from '../../../helpers/styles';

const ServiceItem = props => (
  <Service>
    <ServiceTitle>{props.title}</ServiceTitle>
    <ServiceSubtitle>{props.subtitle}</ServiceSubtitle>
    {props.text.map((paragraph, index) => (
      <ServiceText key={`${props.title.toLowerCase()}-${index}`}>
        {paragraph}
      </ServiceText>
    ))}
  </Service>
);

function ServicesServices(props) {
  return (
    <Section>
      <Wrapper>
        <Content>
          <Intro>
            <IntroTitle>Services</IntroTitle>
            <IntroText>
              Our listing and acquisition services are supported by experts in
              market research and analysis, marketing and advertising, and
              transaction and documentation.
            </IntroText>
          </Intro>
          <Services>
            <ServicesBackgroundImage src='../images/services-map.png' />
            <ServiceItem
              title='Brokerage'
              subtitle='With you, we anticipate changing market conditions, deploy aggressive pricing strategies, track competitive aircraft, and engage the most qualified buyers from around the world.'
              text={[
                'When you list your aircraft for sale by Avpro, we maximize your aircraft’s sale price and minimize its time on market.'
              ]}
            />
            <ServiceDivider />
            <ServiceItem
              title='Acquisition'
              subtitle='We always identify the very best value on the market and prove it with comprehensive aircraft comparisons.'
              text={[
                'We activate our network and intel to acquire your aircraft below market value, including aircraft for sale but not yet identified.'
              ]}
            />
            <ServiceDivider reverse />
            <ServiceItem
              title='Advisory'
              subtitle='Our residual value analysis is so refined that the world’s largest financial institutions consult with us daily to determine lending standards.'
              text={[
                'Our trading floor conducts careful and swift empirical analysis to deliver actionable intelligence before anyone else.',
                'Every day we advise corporate and high net worth clients to protect their investments and select purchasing frameworks that best serve their interests.'
              ]}
            />
          </Services>
        </Content>
      </Wrapper>
    </Section>
  );
}

export default ServicesServices;

const Section = styled(section)`
  background: ${colors.mineShaft};
  overflow: hidden;
`;

const Content = styled.div``;

const Intro = styled.div`
  margin-bottom: ${vars.spacing}px;
`;

const IntroTitle = styled(SectionHeader)``;

const IntroText = styled.p`
  ${font({
    name: 'ivymode',
    responsiveSize: '24px, 32px',
    weight: 200,
    color: colors.alabaster
  })};
  margin: 0;
`;

const Services = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const Service = styled.div`
  max-width: 600px;
`;

const ServiceTitle = styled(SectionTitle)`
  color: ${colors.bermudaGray};
  line-height: 1;
  margin: 0 0 ${vars.gridGap}px;
`;

const ServiceSubtitle = styled.p`
  ${font({
    name: 'ivymode',
    responsiveSize: '24px, 32px',
    weight: 200,
    color: colors.alabaster
  })};
  margin: 0 0 ${vars.gridGap * 1.5}px;
`;

const ServiceText = styled(SectionText)`
  color: ${colors.alabaster};
  font-weight: 400;
  margin: 0;
`;

const ServiceDivider = styled.div`
  margin: ${vars.gridGap * 4}px 0;
  height: 1px;
  background: ${colors.makara};
  width: 100%;
  position: relative;
  display: flex;
  transform: ${props => (props.reverse ? 'rotate(180deg)' : null)};

  &::before {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: ${colors.makara};
    transform: translate(-50%, calc(-50% + 0.5px));
  }

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-bottom: 6px solid transparent; /* left arrow slant */
    border-top: 6px solid transparent; /* right arrow slant */
    border-left: 13px solid ${colors.makara}; /* bottom, add background color here */
    font-size: 0;
    line-height: 0;
    margin-left: auto;
    transform: translate(50%, calc(-50% + 0.5px));
  }
`;

const ServicesBackgroundImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  bottom: 0;
  width: 120vw;
  min-width: 1600px;
  max-height: 80%;
  object-fit: contain;
  transform: translateX(-50%) translateY(-50%);
  opacity: 0.5;
`;
