import React from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import Select from 'react-select';
import { rgba } from 'polished';
import styled from 'styled-components/macro';
import { filterAllParams, filterGlobal, filterOptions } from '../../../hooks';
import {
  vars,
  colors,
  font,
  Wrapper,
  Section as section,
  mediaQuery,
} from '../../../helpers/styles';
import { Grid, GridCell, Value, GridDataCell } from '../../global/Grid';
import { Icon, ChevronRight } from '../../Icons';
import InventoryFilter from './InventoryFilter';
import InventoryNoResults from './InventoryNoResults';

function InventoryResults(props) {
  const { planes } = props;

  const g = queryString.parse(props.location.search);

  let filteredPlanes = planes
    .filter((plane) => {
      if (g['name']) {
        return filterGlobal(g['name'], plane);
      }
      return true;
    })
    .filter((plane) => {
      return filterAllParams(g, plane);
    });

  // MAKE SURE THAT THESE ARE EXCLUDED FROM 'filterAllParams' hook!!!!
  const filterParams = [
    'flight_range',
    'year_model',
    'airframe_total_time',
    'price',
  ];

  filterParams.forEach((param) => {
    if (g[param]) {
      filteredPlanes = filteredPlanes.filter((plane) => {
        if (plane[param]) {
          return filterOptions(param, g, plane);
        }
        return null;
      });
    }
  });

  const show = filteredPlanes.map((plane, index) => {
    const planeNameId = `${plane['manufacturer']} ${plane['model']} SN:${plane['serial_number']}`;
    const planeNameIdFiltered = planeNameId
      .replace(/ /g, '-')
      .toLocaleLowerCase();
    return (
      <Result key={planeNameIdFiltered + index} to={`/${planeNameIdFiltered}`}>
        <ResultBox>
          <ResultTitle>
            {/* {plane['hero_cover'] ? (
              <ResultAircraftImage src={plane['hero_cover']} alt='' />
            ) : null} */}
            <ResultAircraftImage
              src={
                plane['hero_cover']
                  ? plane['hero_cover']
                  : '../images/aircraft-placeholder.svg'
              }
              alt=''
            />
            {plane['year_model']} {plane['manufacturer']} {plane['model']}
          </ResultTitle>
          <ResultSerial>SN: {plane['serial_number']}</ResultSerial>
        </ResultBox>
        <ResultInfo>
          <GridCell hasValue noPadding>
            <ResultInnerGrid noPadding>
              <GridDataCell label='Cabin Class' value={plane['cabin_class']} />
              <GridDataCell label='Range' value={`${plane['flight_range']} NM`} />
              <GridDataCell
                label='Passengers'
                value={plane['total_passengers']}
              />
            </ResultInnerGrid>
          </GridCell>
          <GridCell hasValue noPadding>
            <PricingGrid noPadding>
              <ResultPrice
                smallLabel={
                  plane['display_price'] !== undefined &&
                  plane['display_price'] !== '' &&
                  plane['display_price'] !== null
                    ? true
                    : false
                }>
                {plane['display_price'] !== undefined &&
                plane['display_price'] !== '' &&
                plane['display_price'] !== null
                  ? plane['display_price']
                  : plane['price']}
              </ResultPrice>
              <ResultCta>
                View Listing{' '}
                <CtaIconBox>
                  <CtaIcon>
                    <ChevronRight />
                  </CtaIcon>
                </CtaIconBox>
              </ResultCta>
            </PricingGrid>
          </GridCell>
        </ResultInfo>
      </Result>
    );
  });

  const planeCountLimit = 40;

  return (
    <Wrapper sidebar={true}>
      <Section>
        <ResultsIntro>
          <ResultsAvailable>
          {filteredPlanes.length >= planeCountLimit && 
            <ResultsAvailableNumber>
              {filteredPlanes.length}
            </ResultsAvailableNumber>
          }
            <ResultsAvailableText>{filteredPlanes.length < planeCountLimit && "Current"} Listings</ResultsAvailableText>
          </ResultsAvailable>
          <ResultsSort>
            <ResultsSortLabel>Sort by:</ResultsSortLabel>
            <StyledSelect
              styles={selectStyles}
              options={options}
              isSearchable={false}
              defaultValue={options[0]}
              onChange={(value) => {
                props.onSort(value);
              }}
            />
          </ResultsSort>
        </ResultsIntro>
        {filteredPlanes.length !== 0 ? (
          <Results>{show}</Results>
        ) : Object.entries(g).length !== 0 ? (
          <InventoryNoResults />
        ) : null}
      </Section>
      <InventoryFilter
        filterState={props.filterState}
        setFilterState={props.setFilterState}
        scrollToMain={props.scrollToMain}
        toggleLockScroll={props.toggleLockScroll}
      />
    </Wrapper>
  );
}

export default InventoryResults;

// Sort Styles
const selectStyles = {
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'realistwide, sans-serif',
    fontWeight: 400,
    letterSpacing: '0.5px',
    background: state.isSelected ? colors.makara : colors.white,
    transition: '100ms',
    '&:hover': {
      background: state.isSelected ? colors.makara : colors.tea,
    },
  }),
  control: (provided, state) => ({
    fontFamily: 'realistwide, sans-serif',
    fontWeight: 600,
    letterSpacing: '0.5px',
    boxShadow: state.isFocused
      ? `inset 0 0 0 1px ${rgba(colors.mineShaft, 0.5)}`
      : `inset 0 0 0 1px ${rgba(colors.mineShaft, 0.1)}`,
    border: 'none',
    display: 'flex',
    cursor: 'pointer',
    transition: '200ms',
    background: state.isFocused ? colors.white : 'transparent',

    '&:hover': {
      background: colors.white,
      boxShadow: `inset 0 0 0 1px ${rgba(colors.mineShaft, 0.5)}`,
    },
  }),
  indicatorSeparator: () => ({}),
  dropdownIndicator: (provided, state) => ({
    display: 'flex',
    color: colors.mineShaft,
    padding: vars.gridGap / 2,
  }),
};

// Sort Options
const options = [
  { value: 'price', label: 'Price' },
  { value: 'most passengers', label: 'Most Passengers' },
  { value: 'manufacturer a-z', label: 'Manufacturer' },
  { value: 'newest model year', label: 'Newest Model Year' },
  { value: 'longest range', label: 'Longest Range' },
];

const Section = styled(section)`
  min-height: calc(100vh - ${vars.mobileNavBar});

  &:first-of-type {
    padding: ${vars.spacing * 0.75}px 0;
  }

  @media (min-width: 1200px) {
    min-height: 100vh;
  }
`;

const ResultsIntro = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: ${vars.gridGap * 2}px;
  /* flex-direction: column; */
  flex-direction: column-reverse;

  @media ${mediaQuery.sm} {
    align-items: flex-end;
    flex-direction: column;
  }

  @media ${mediaQuery.md} {
    align-items: center;
    flex-direction: row;
  }
`;

const ResultsAvailable = styled.div`
  margin-top: ${vars.gridGap}px;
  display: flex;

  @media ${mediaQuery.sm} {
    margin-top: 0;
  }
`;

const ResultsAvailableText = styled.p`
  ${font({
    name: 'realist',
    size: '18px',
    weight: 600,
    color: colors.mineShaft,
    letterSpacing: '0.5px',
    transform: 'uppercase',
  })};
  display: block;
  margin: 3px 0 0;
`;
const ResultsAvailableNumber = styled(ResultsAvailableText)`
  font-size: 22px;
  margin: 0 ${vars.gridGap / 2}px 0 0;
`;

const ResultsSort = styled.div`
  display: flex;
  flex-direction: column;

  @media ${mediaQuery.sm} {
    margin-top: ${vars.gridGap}px;
    width: 300px;
    align-items: center;
    flex-direction: row;
  }

  @media ${mediaQuery.md} {
    margin-top: 0;
  }
`;

const ResultsSortLabel = styled.p`
  ${font({
    name: 'realist',
    size: '16px',
    weight: 400,
    color: colors.tapa,
  })};
  margin: 0 0 ${vars.gridGap / 2}px 0;

  @media ${mediaQuery.sm} {
    margin: 0 ${vars.gridGap / 2}px 0 0;
  }
`;

const StyledSelect = styled(Select)`
  width: 220px;

  @media ${mediaQuery.sm} {
    flex: 1;
    width: auto;
  }
`;

const Results = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: minmax(250px, 350px);
  grid-gap: ${vars.gridGap * 3}px;
  justify-content: space-around;

  @media ${mediaQuery.sm} {
    margin-left: -${vars.gridGap * 1.5}px;
    width: calc(100% + ${vars.gridGap * 3}px);
    grid-template-columns: repeat(2, minmax(250px, 350px));
  }

  @media (min-width: 1200px) {
    grid-gap: ${vars.gridGap}px;
    grid-template-columns: 1fr;
  }
`;

const Result = styled(Link)`
  display: grid;
  text-decoration: none;
  padding: ${vars.gridGap * 1.5}px;
  transition: background 150ms, box-shadow 150ms;
  grid-template-columns: 1fr;

  ${GridCell} {
    transition: 150ms;
  }

  &:hover {
    background: ${colors.white};
    box-shadow: 0 3px 40px rgba(0, 0, 0, 0.16);

    ${GridCell} {
      background: ${colors.white};
    }
  }

  @media (min-width: 1200px) {
    padding: ${vars.gridGap}px ${vars.gridGap * 1.5}px;
    grid-template-columns: 0.75fr 1fr;
  }
`;

const ResultBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0 ${vars.gridGap}px;
  align-items: center;

  @media (min-width: 1200px) {
    align-items: flex-start;
  }
`;

const ResultTitle = styled.h4`
  ${font({
    name: 'realist',
    size: '18px',
    weight: 600,
    color: colors.mineShaft,
    letterSpacing: '0.5px',
    transform: 'uppercase',
  })};
  margin: 0 0 ${vars.gridGap / 2}px;
  text-align: center;

  @media (min-width: 1200px) {
    text-align: start;
  }
`;

const ResultSerial = styled.p`
  ${font({
    name: 'realist',
    size: '10px',
    weight: 600,
    color: colors.mineShaft,
    letterSpacing: '1px',
    transform: 'uppercase',
  })};
  margin: 0;
`;

const ResultAircraftImage = styled.img`
  width: 80%;
  max-width: 200px;
  object-fit: contain;
  display: block;
  margin: auto auto ${vars.gridGap}px;
  height: 60px;

  @media (min-width: 1200px) {
    margin: 0 auto ${vars.gridGap}px 0;
  }
`;

const ResultInfo = styled(Grid)`
  display: block;
  padding: 0 0 0 1px;
  background: transparent;
  grid-gap: 0;
  align-self: flex-end;
  margin: auto 0 0;
  border-top: 1px solid ${colors.alto};
  padding-top: ${vars.gridGap}px;

  ${Value} {
    font-size: 14px;
  }

  ${GridCell} {
    justify-content: center;
    padding: 0;
  }

  @media (min-width: 1200px) {
    padding-top: 0;
    border-top: none;
    margin-top: 0;
    display: grid;
    grid-gap: 1px;
    background: ${colors.alto};
    margin-left: ${vars.gridGap * 2}px;
  }
`;

const ResultInnerGrid = styled(Grid)`
  grid-template-columns: 1fr;
  grid-gap: 0;

  ${GridCell} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0;
    grid-gap: ${vars.gridGap}px;
  }

  ${GridCell} + ${GridCell} {
    margin-top: ${vars.gridGap / 2}px;
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 33.333%);

    ${GridCell} {
      display: grid;
      grid-template-columns: 1fr;
      padding: ${vars.gridGap}px;
      grid-gap: 0;
    }

    ${GridCell} + ${GridCell} {
      margin-top: 0;
    }
  }
`;

const PricingGrid = styled(Grid)`
  grid-template-columns: 1fr;
  grid-gap: 0;
  margin-top: ${vars.gridGap / 2}px;

  /* ${GridCell} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0;
    grid-gap: ${vars.gridGap}px;
  }

  ${GridCell} + ${GridCell} {
    margin-top: ${vars.gridGap /
  2}px;
  } */

  @media (min-width: 1200px) {
    margin-top: 0;
    grid-template-columns: 1fr 1fr;
    padding: ${vars.gridGap}px 0 ${vars.gridGap}px ${vars.gridGap}px;
  }
`;

const ResultPrice = styled.p`
  ${font({
    name: 'realist',
    size: '14px',
    weight: 400,
    color: colors.black,
  })};
  padding: 0 0 0 calc(50% + ${vars.gridGap / 2}px);
  position: relative;
  margin: 0;

  &::before {
    ${font({
      name: 'realist',
      size: '12px',
      weight: 600,
      color: colors.makara,
      letterSpacing: '1.1px',
      transform: 'uppercase',
    })};
    content: 'price';
    position: absolute;
    left: 0;
  }

  @media (min-width: 1200px) {
    ${font({
      letterSpacing: '0.2px',
      color: colors.makara,
    })};
    font-size: ${(props) => (props.smallLabel ? '18px' : '25px')};
    padding-left: 0;

    &::before {
      content: none;
    }
  }
`;

const ResultCta = styled.div`
  ${font({
    name: 'realist',
    size: '18px',
    weight: 600,
    color: colors.blueGem,
    letterSpacing: '0.2px',
  })};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${vars.gridGap * 1.5}px auto 0;
  width: 200px;
  background: ${colors.blueGem};
  padding: ${vars.gridGap / 2}px ${vars.gridGap}px;
  color: ${colors.white};
  border-radius: 25px;

  @media (min-width: 1200px) {
    margin: 0 0 0 auto;
    opacity: 0;
    transform: translateX(-10px);
    transition: 150ms ease-out;
    border-radius: 0;
    background: transparent;
    padding: 0;
    color: ${colors.blueGem};

    ${Result}:hover & {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

const CtaIconBox = styled.span`
  height: 25px;
  width: 25px;
  border-radius: 50px;
  background-color: ${colors.blueGem};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 200ms;
  margin-left: ${vars.gridGap / 2}px;
`;

const CtaIcon = styled(Icon)`
  height: 10px;
  width: 10px;
  fill: ${colors.white};
`;
