import React from 'react';
import styled from 'styled-components/macro';
import {
  vars,
  colors,
  font,
  Wrapper,
  Section as section,
  SectionTitle as sectionTitle
} from '../../../helpers/styles';

function CompanyIntegrity(props) {
  return (
    <Section>
      <Wrapper>
        <SectionTitle>Integrity</SectionTitle>
        <Feature>
          <FeatureText>
            In a market that has been unregulated since its inception, people
            trust Avpro because we do the right thing the first time. We put our
            clients’ interests ahead of our own.
          </FeatureText>
        </Feature>
      </Wrapper>
    </Section>
  );
}

export default CompanyIntegrity;

const Section = styled(section)`
  background-color: ${colors.mineShaft};
`;

const SectionTitle = styled(sectionTitle)`
  color: ${colors.bermudaGray};
`;

const Feature = styled.div`
  border-top: 1px solid ${colors.bermudaGray};
  border-bottom: 1px solid ${colors.bermudaGray};
  padding: ${vars.gridGap * 3}px 0;
`;

const FeatureText = styled.p`
  ${font({
    name: 'ivymode',
    responsiveSize: '24px, 32px',
    weight: 200,
    color: colors.white,
    lineHeight: 1.5
  })};
  margin: 0;

  & + & {
    margin-top: ${vars.gridGap * 2}px;
  }
`;
