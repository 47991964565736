import React from 'react';
import styled from 'styled-components/macro';
import CompanyHero from './components/CompanyHero';
import CompanyIntro from './components/CompanyIntro';
import CompanyKnowledge from './components/CompanyKnowledge';
import CompanyPerformance from './components/CompanyPerformance';
import CompanyIntegrity from './components/CompanyIntegrity';
import CompanyJourney from './components/CompanyJourney';
import CompanyCTA from './components/CompanyCTA';
import Footer from '../Footer';

function Company(props) {
  return (
    <>
      <CompanyHero toggleScrolledNav={props.toggleScrolledNav} />
      <Content>
        <CompanyIntro />
        <CompanyKnowledge />
        <CompanyPerformance />
        <CompanyIntegrity />
        <CompanyJourney />
      </Content>
      <CompanyCTA />
      <Footer />
    </>
  );
}

export default Company;

const Content = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
