import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import {
  vars,
  colors,
  font,
  mediaQuery,
  Wrapper,
  Section as section,
  SectionContent,
  SectionTitle,
  SectionText,
  SectionSubtitle
} from '../../../helpers/styles';
import { Grid as grid, GridCell, Label, Value } from '../../global/Grid';
import { Button as button } from '../../global/Button';

function CompanyCTA(props) {
  return (
    <Section altColor>
      <Wrapper>
        <SectionContent>
          <SectionTitle>Start the Conversation</SectionTitle>
          <SectionText>
            Active, timely, personalized. We’re ready to guide you through this
            process, as one of the world's largest international aircraft
            brokerage teams. We look forward to hearing from you.
          </SectionText>
        </SectionContent>
        <SectionContent>
          <SectionSubtitle>Drop us a line</SectionSubtitle>
          <Grid>
            <GridCell hasValue altColor>
              <Button as={Link} to='/'>
                View Inventory
              </Button>
            </GridCell>
            <GridCell hasValue altColor>
              <Button as={Link} secondary='true' to='/contact'>
                Contact Us
              </Button>
            </GridCell>
          </Grid>
        </SectionContent>
      </Wrapper>
    </Section>
  );
}

export default CompanyCTA;

const Section = styled(section)`
  background: ${colors.mineShaft};
  z-index: 1;

  ${props =>
    props.altColor &&
    css`
      ${SectionTitle} {
        color: ${colors.bermudaGray};
        text-align: center;
      }

      ${SectionText} {
        ${font({
          size: '20px',
          color: colors.white
        })};
        max-width: 550px;
        margin: 0 auto;
        position: relative;

        &::before {
          content: '—';
          margin-right: ${vars.gridGap / 2}px;
        }

        @media ${mediaQuery.sm} {
          &::before {
            position: absolute;
            left: 0;
            transform: translateX(calc(-100% - ${vars.gridGap / 2}px));
          }
        }
      }

      ${SectionSubtitle} {
        color: ${colors.makara};
        text-align: center;
      }

      ${Label} {
        color: ${colors.makara};
        font-weight: 800;
        margin: ${vars.gridGap}px 0;
      }

      ${Value} {
        color: ${colors.white};
        font-weight: 200;
      }

      ${Grid} {
        background: ${props =>
          props.noPadding ? 'transparent' : colors.tundora};
      }
    `}
`;

const Grid = styled(grid)`
  grid-template-columns: 1fr;

  @media ${mediaQuery.sm} {
    grid-template-columns: 1fr 1fr;
  }
`;

const Button = styled(button)`
  margin: ${vars.gridGap}px auto;
`;
