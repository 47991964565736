import React from 'react';
import styled, { css } from 'styled-components/macro';
import { vars, colors, font, mediaQuery } from '../../../helpers/styles';
import { Button } from '../../global/Button';

function PlaneSidebar(props) {
  const handleClick = (e) => {
    e.preventDefault();

    switch (e.target.getAttribute('href')) {
      case '#highlights':
        window.scrollTo({
          top: props.highlightRef.current.offsetTop + 1,
          behavior: 'smooth',
        });
        break;

      case '#metrics':
        window.scrollTo({
          top: props.metricsRef.current.offsetTop - 65,
          behavior: 'smooth',
        });
        break;

      case '#interior':
        window.scrollTo({
          top: props.interiorRef.current.offsetTop,
          behavior: 'smooth',
        });
        break;

      case '#exterior':
        window.scrollTo({
          top: props.exteriorRef.current.offsetTop - 65,
          behavior: 'smooth',
        });
        break;

      case '#airframe':
        window.scrollTo({
          top: props.airframeRef.current.offsetTop,
          behavior: 'smooth',
        });
        break;

      default:
        break;
    }
  };

  const details = props.details;

  // details shorthand (e. detail('manufacturer'))
  function detail(key) {
    return details[key];
  }

  const offerClick = (e) => {
    e.preventDefault();
    props.setOfferModal(true);
  };

  return (
    <Sidebar>
      <SidebarContent>
        <Button
          data-gtm='inquire'
          href={`mailto:${
            detail('sales_rep_email') !== undefined &&
            detail('sales_rep_email') !== '' &&
            detail('sales_rep_email') !== null
              ? `${
                  detail('sales_rep_name')
                    ? `${detail('sales_rep_name')}<${detail(
                        'sales_rep_email'
                      )}>`
                    : `${detail('sales_rep_email')}`
                }`
              : 'info@avprojets.com'
          }?subject=Inquiry Into ${detail('year_model')} ${detail(
            'manufacturer'
          )} ${detail('model')} SN:${detail('serial_number')}${
            detail('sales_rep_name') !== undefined &&
            detail('sales_rep_name') !== '' &&
            detail('sales_rep_name') !== null
              ? `&body=To ${detail('sales_rep_name')},`
              : '&body=To Whomever it May Concern,'
          }${
            detail('sales_rep_email') !== undefined &&
            detail('sales_rep_email') !== '' &&
            detail('sales_rep_email') !== null
              ? `&bcc=info@avprojets.com`
              : ''
          }
          `}
          target='_blank'
          rel='noopener noreferrer'>
          Inquire
        </Button>
        <Aircraft>
          <AircraftImage
            src={
              detail('hero_cover')
                ? detail('hero_cover')
                : '../images/aircraft-placeholder.svg'
            }
            alt=''
          />

          <AircraftTitle>
            {detail('year_model')} {detail('manufacturer')} {detail('model')}
          </AircraftTitle>
          <AircraftSerial>SN {detail('serial_number')}</AircraftSerial>
        </Aircraft>
        <Nav>
          {detail('highlight1') &&
          detail('highlight2') &&
          detail('highlight3') ? (
            <NavLink href='#highlights' onClick={handleClick}>
              Highlights
            </NavLink>
          ) : null}
          <NavLink href='#metrics' onClick={handleClick}>
            Metrics &amp; Performance
          </NavLink>
          <NavLink href='#interior' onClick={handleClick}>
            Interior
          </NavLink>
          <NavLink href='#exterior' onClick={handleClick}>
            Exterior
          </NavLink>
          <NavLink href='#airframe' onClick={handleClick}>
            Airframe &amp; Engines
          </NavLink>
        </Nav>
        <Pricing>
          <PricingText>Price</PricingText>
          <PricingPrice>
            {detail('display_price') !== undefined &&
            detail('display_price') !== '' &&
            detail('display_price') !== null
              ? detail('display_price')
              : detail('price')}
          </PricingPrice>
          <Button
            as={'button'}
            data-gtm='offer form'
            primary
            onClick={offerClick}>
            Make an Offer
          </Button>
        </Pricing>
      </SidebarContent>
    </Sidebar>
  );
}

export default PlaneSidebar;

const Sidebar = styled.div`
  display: none;

  @media ${mediaQuery.lg} {
    width: 300px;
    height: 100vh;
    background: ${colors.white};
    position: sticky;
    right: 0;
    top: 0;
    z-index: 0;
    padding: ${vars.gridGap * 6}px ${vars.gridGap * 2.5}px ${vars.gridGap * 4}px;
    margin-left: auto;
    display: block;
  }
`;

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Aircraft = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${vars.gridGap * 2}px;
  text-align: center;
`;

const AircraftImage = styled.img`
  width: 80%;
  max-width: 200px;
  object-fit: contain;
  margin: 0 auto ${vars.gridGap}px;
  display: block;
`;

const AircraftTitle = styled.p`
  ${font({
    name: 'realist',
    size: '16px',
    weight: 400,
    color: colors.mineShaft,
    letterSpacing: '1px',
    transform: 'uppercase',
  })};
  margin: 0 0 ${vars.gridGap}px;
`;

const AircraftSerial = styled.p`
  ${font({
    name: 'realist',
    size: '12px',
    weight: 400,
    color: colors.makara,
    letterSpacing: '1px',
    transform: 'uppercase',
  })};
  margin: 0;
`;

const Nav = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5vh 0 auto;
`;

const NavLink = styled.a`
  ${font({
    name: 'realist',
    size: '12px',
    weight: 600,
    color: colors.makara,
    letterSpacing: '1px',
    transform: 'uppercase',
  })};
  margin: 0;
  padding: ${vars.gridGap / 2}px 0;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  ${(props) =>
    props.isActive &&
    css`
      color: red;
    `}
`;

const Pricing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5vh 0 0;
`;

const PricingText = styled.p`
  ${font({
    name: 'realist',
    size: '12px',
    weight: 600,
    color: colors.makara,
    letterSpacing: '1px',
    transform: 'uppercase',
  })};
  margin: 0 0 ${vars.gridGap / 2}px;
`;

const PricingPrice = styled.h4`
  ${font({
    name: 'realist',
    size: '25px',
    weight: 400,
    color: colors.mineShaft,
    letterSpacing: '1px',
  })};
  margin: 0 0 ${vars.gridGap * 2}px;
`;
