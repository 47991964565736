import React from 'react';
import styled, { css } from 'styled-components/macro';
import { rgba } from 'polished';
import { colors, mediaQuery } from '../../../helpers/styles';
import {
  Icon,
  Filter as FilterCtaIcon,
  Close as FilterCtaIconClose
} from '../../Icons';

function InventoryFilter(props) {
  const handleClick = e => {
    if (!props.filterState) {
      props.setFilterState(true);
      props.toggleLockScroll(true);
      props.scrollToMain();
    } else {
      props.setFilterState(false);
      props.toggleLockScroll(false);
    }
  };

  return (
    <>
      <FilterScrim filterState={props.filterState} onClick={handleClick} />
      <FilterCta filterState={props.filterState} onClick={handleClick}>
        <FilterCtaIconBox>
          <FilterCtaIcon />
          <FilterCtaIconClose />
        </FilterCtaIconBox>
      </FilterCta>
    </>
  );
}

export default InventoryFilter;

const FilterScrim = styled.div`
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: rgba(30, 30, 30, 0.9);
  visibility: hidden;
  opacity: 0;
  z-index: 6;
  transition: 250ms;

  ${props =>
    props.filterState &&
    css`
      visibility: visible;
      opacity: 1;
    `}

  @media ${mediaQuery.lg} {
    display: none;
  }
`;

const FilterCtaIconBox = styled(Icon)`
  height: 24px;
  width: 24px;
  transition: 300ms ease;
  position: relative;

  svg {
    transition: 300ms;
  }

  svg:nth-of-type(2) {
    height: 20px;
    width: 20px;
    position: absolute;
    transform: scale(0.5);
    opacity: 0;
  }
`;

const FilterCta = styled.button`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: ${colors.white};
  box-shadow: 0 3px 6px ${rgba(colors.black, 0.15)},
    0 6px 12px ${rgba(colors.black, 0.15)};
  bottom: 24px;
  right: 24px;
  position: sticky;
  margin: -50px 0 24px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 200ms;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 8;

  &:hover {
    box-shadow: 0 5px 10px ${rgba(colors.black, 0.25)},
      0 5px 20px ${rgba(colors.black, 0.25)};
  }

  ${props =>
    props.filterState &&
    css`
      ${FilterCtaIcon} {
        transform: rotate(180deg);

        svg:nth-of-type(1) {
          transform: scale(0.5);
          opacity: 0;
        }

        svg:nth-of-type(2) {
          position: absolute;
          transform: scale(1);
          opacity: 1;
        }
      }
    `}

  @media ${mediaQuery.lg} {
    display: none;
  }
`;
