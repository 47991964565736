import styled, { createGlobalStyle, css } from 'styled-components/macro';

const gridGap = 16;

export const vars = {
  gridGap: gridGap,
  spacing: gridGap * 5.75,
  navBar: '120px',
  mobileNavBar: '65px',
  sidebar: '300px',
  siteWidth: '1440px',
};

export const colors = {
  carnation: '#f15358',
  bermudaGray: '#6F91A6',
  blueBayoux: '#546A77',
  blueGem: '#34109B',
  white: '#FFF',
  alabaster: '#F8F8F8',
  alto: '#DBDBDB',
  timberwolf: '#DBD7D2',
  gray: '#8F8F8F',
  tea: '#BFB8AE',
  makara: '#8C8070',
  tapa: '#787672',
  tundora: '#4B4B4B',
  mineShaft: '#262626',
  black: '#000',
};

// Site Breakpoints
export const breakpoints = {
  xs: '380px', // Mobile
  sm: '600px', // Mobile-lg
  md: '768px', // Tablet
  lg: '1024px', // Laptop
  xl: '1440px', // Desktop
};

export const mediaQuery = Object.keys(breakpoints).reduce((acc, cur) => {
  acc[cur] = `(min-width: ${breakpoints[cur]})`;
  return acc;
}, {});

export function font(props) {
  /**
   * Possible Props:
   *   * name
   *   * size
   *   * responsiveSize (minFont, maxFont, minWidth, maxWidth) [ONLY in px]
   *   * color
   *   * weight
   *   * transform
   *   * lineHeight
   *   * letterSpacing
   */

  // Handle various font names
  if (props.name) {
    const fontFamily = props.name.toLowerCase();
    switch (fontFamily) {
      case 'realist':
        props.name = 'realistwide, sans-serif';
        break;
      case 'ivymode':
        props.name = 'ivymode, sans-serif';
        break;
      default:
        props.name = 'ivymode, sans-serif';
    }
  }

  // Handle Responsive Font Sizing
  function resizeFont() {
    if (props.responsiveSize) {
      // create array for each number
      let splitSizes = props.responsiveSize.split(/[ ,]+/);

      // get only integers for each value of array (remove px)
      splitSizes.forEach((size, index) => {
        splitSizes[index] = splitSizes[index].replace(/\D+/g, '');
      });

      // assign value to appropriate variable
      const minSize = splitSizes[0];
      const maxSize = splitSizes[1];
      const minWidth = splitSizes[2] || '420'; // defaults to 420px wide min
      const maxWidth = splitSizes[3] || '1280'; // defaults to 1280 wide max

      // responsive typography
      return `
        font-size: calc(${minSize}px + (${maxSize} - ${minSize}) * ((100vw - ${minWidth}px) / (${maxWidth} - ${minWidth})));

        @media screen and (max-width: ${minWidth}px) {
          font-size: ${minSize}px;
        };

        @media screen and (min-width: ${maxWidth}px) {
          font-size: ${maxSize}px;
        }
      `;
    }
  }

  return css`
  ${props.name ? `font-family: ${props.name};` : null}
  ${props.size ? `font-size: ${props.size};` : null}
  ${props.responsiveSize ? resizeFont() : null}
  ${props.color ? `color: ${props.color};` : null}
  ${props.weight ? `font-weight: ${props.weight};` : null}
  ${props.transform ? `text-transform: ${props.transform};` : null}
  ${props.lineHeight ? `line-height: ${props.lineHeight};` : null}
  ${props.letterSpacing ? `letter-spacing: ${props.letterSpacing};` : null}
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;
}

export const hideText = () => {
  return css`
    text-indent: -9999px;
    font-size: 0;
    line-height: 0;
  `;
};

export const GlobalStyle = createGlobalStyle`
  /* Box-Sizing Preset / Font Sizing Preset */
  html {
    box-sizing: border-box;
    padding: 0;
    min-width: 320px;
    overflow-x: hidden;
    font-size: 62.5%; /* 1em = 10px */
    padding-top: ${vars.mobileNavBar};

    /* @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      padding-top: 0;
    } */

    @media ${mediaQuery.lg} {
      padding-top: 0;
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  * {
    &::-moz-focus-inner {
      border: 0;
    }

    &:focus {
      outline-offset: 2px;
    }
  }

  body {
    width: 100%;
    max-width: 100vw;
    min-height: 100vh;
    margin: 0;
    font-size: 1.6rem;
  }

  .fslightbox-image {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain !important;
  }
`;

export const Wrapper = styled.div`
  padding: 0 ${vars.gridGap * 1.5}px;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;

  @media ${mediaQuery.sm} {
    padding: 0 ${vars.gridGap * 3.5}px;
  }

  @media ${mediaQuery.xl} {
    padding: 0 ${vars.gridGap * 3.5}px;
  }
`;

export const Section = styled.section`
  position: relative;
  width: 100%;
  padding: ${vars.spacing}px 0;

  @media ${mediaQuery.lg} {
    padding: ${vars.spacing * 1.5}px 0;
  }

  /* &:first-of-type {
    padding-top: ${vars.spacing}px;
  } */

  ${(props) =>
    props.clearTop &&
    css`
      padding-top: 0 !important;
    `}

  ${(props) =>
    props.clearBottom &&
    css`
      padding-bottom: 0 !important;
    `}

  @media ${mediaQuery.lg} {
    padding: ${vars.spacing}px 0;
  }
`;

export const SectionContent = styled.div`
  width: 100%;

  & + & {
    margin-top: ${vars.gridGap * 4}px;
  }
`;

export const SectionHeader = styled.h5`
  ${font({
    name: 'realist',
    size: '1.2rem',
    weight: 800,
    color: colors.makara,
    transform: 'uppercase',
    letterSpacing: '1.1px',
  })};
  margin: 0 0 ${vars.gridGap * 1.5}px;
`;

export const SectionTitle = styled.h1`
  ${font({
    name: 'ivymode',
    responsiveSize: '32px, 57px',
    weight: 200,
    color: colors.makara,
    letterSpacing: '-0.3px',
  })};
  margin: 0 0 ${vars.gridGap * 2}px;
`;

export const SectionSubtitle = styled.h2`
  ${font({
    name: 'realist',
    responsiveSize: '18px, 22px',
    weight: 400,
    color: colors.black,
    letterSpacing: '0.8px',
    transform: 'uppercase',
  })};
  margin: 0 0 ${vars.gridGap}px;
`;

export const SectionText = styled.p`
  ${font({
    name: 'realist',
    size: '18px',
    weight: 200,
    color: colors.black,
    letterSpacing: '0.5px',
    lineHeight: 1.4,
  })};
  margin: 0;
`;
