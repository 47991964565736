import React from 'react';
import styled from 'styled-components/macro';
import {
  vars,
  colors,
  mediaQuery,
  Wrapper,
  Section as section,
  SectionContent,
  SectionTitle as sectionTitle,
} from '../../../helpers/styles';
import {
  Grid as grid,
  GridCell as gridCell,
  GridCellContent,
  GridContentCell,
} from '../../global/Grid';

function PlaneExterior(props) {
  const details = props.details;

  // details shorthand (e. detail('manufacturer'))
  function detail(key) {
    return details[key];
  }

  return (
    <Section ref={props.exteriorRef}>
      <Wrapper>
        <SectionContent>
          <SectionTitle>Exterior</SectionTitle>
          {detail('hero_cover') ? (
            <IsolatedImage src={detail('hero_cover')} alt='' />
          ) : null}
          <Grid>
            {detail('exterior1') ? (
              <GridCell spanRow={2}>
                <BackgroundImageLink
                  href={detail('exterior1')}
                  target='_blank'
                  rel='noopener noreferrer'
                  data-gtm='image'
                  onClick={(e) => {
                    e.preventDefault();
                    props.setLightbox(!props.lightbox);
                    props.setLightboxSrc(
                      <img
                        src={detail('exterior1')}
                        alt=''
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    );
                  }}>
                  <BackgroundImage src={detail('exterior1')} alt='' />
                </BackgroundImageLink>
              </GridCell>
            ) : null}
            <GridContentCell label='Base Color' value={detail('base_color')} />
            <GridContentCell label='accent' value={detail('accent')} />
          </Grid>
        </SectionContent>
      </Wrapper>
    </Section>
  );
}

export default PlaneExterior;

const Section = styled(section)``;

const SectionTitle = styled(sectionTitle)`
  text-align: left;

  @media ${mediaQuery.md} {
    text-align: center;
  }
`;

const IsolatedImage = styled.img`
  width: 100%;
  object-fit: contain;
  margin-bottom: ${vars.gridGap * 4}px;

  @media ${mediaQuery.xl} {
    width: 110%;
  }
`;

const Grid = styled(grid)`
  grid-template-columns: 1fr 1fr;

  ${GridCellContent} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  @media ${mediaQuery.md} {
    grid-template-columns: 0.5fr 1fr;
  }
`;

const GridCell = styled(gridCell)`
  padding: 0;
  display: flex;

  &:nth-of-type(1) {
    display: none;

    @media ${mediaQuery.md} {
      box-shadow: 0 1px ${colors.alabaster}, 0 -1px ${colors.alabaster};
      display: block;
      padding: 0 ${vars.gridGap * 1.5}px 0 0;
    }
  }

  &:nth-of-type(2n + 4) {
    box-shadow: inset 1px 0 ${colors.tundora}, inset 0 -1px ${colors.tundora};
  }

  &:nth-of-type(4) {
    box-shadow: inset 1px 1px ${colors.tundora}, inset 0 -1px ${colors.tundora};
  }
`;

const BackgroundImageLink = styled.a`
  text-decoration: none;
  display: block;
`;

const BackgroundImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`;
