import React from 'react';
import Observer from '@researchgate/react-intersection-observer';
import styled, { css } from 'styled-components/macro';
import { rgba } from 'polished';
import {
  vars,
  colors,
  font,
  mediaQuery,
  Wrapper,
  Section as section,
} from '../../../helpers/styles';
import {
  Icon,
  ArrowDown,
  Brochure,
  Video,
  Gallery,
  VirtualTour,
} from '../../Icons';
import { Button } from '../../global/Button';
import PlaneGallery from './PlaneGallery';

function PlaneHero(props) {
  const details = props.details;

  // details shorthand (e.g. detail('manufacturer'))
  function detail(key) {
    return details[key];
  }

  // const galleryToggler = e => {
  //   if (e) e.preventDefault();

  //   setGalleryToggle(!galleryToggle);
  // };

  const offerClick = (e) => {
    e.preventDefault();
    props.setOfferModal(true);
  };

  const handleIntersection = (e) => {
    props.toggleScrolledNav(!e.isIntersecting);
  };

  const handleClick = (e) => {
    e.preventDefault();

    if (
      detail('highlight1') &&
      detail('highlight2') &&
      detail('highlight3')
    ) {
      window.scrollTo({
        top: props.highlightRef.current.offsetTop + 1,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: props.metricsRef.current.offsetTop + 1,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Observer onChange={handleIntersection} rootMargin='0px 0px 0px'>
      <Heros>
        <Hero>
          <HeroBackground>
            <HeroBackgroundImage src='../images/hero-plane.jpeg' />
          </HeroBackground>
          <Wrapper>
            <Section>
              <HeroContent>
                <HeroTaglines>
                  <HeroTagline>{detail('year_model')}</HeroTagline>
                  <HeroTagline>{detail('manufacturer')}</HeroTagline>
                  <HeroTagline serialNumber>
                    {detail('serial_number')}
                  </HeroTagline>
                </HeroTaglines>
                <HeroTitle>{detail('model')}</HeroTitle>
                <HeroText>{detail('short_summary')}</HeroText>
                <HeroScroll
                  href={
                    detail('highlight1') &&
                    detail('highlight2') &&
                    detail('highlight3')
                      ? '#highlights'
                      : '#metrics'
                  }
                  onClick={handleClick}>
                  <HeroScrollIcon>
                    <ArrowDown />
                  </HeroScrollIcon>
                </HeroScroll>
              </HeroContent>
              {detail('hero_cover') ? (
                <HeroImage src={detail('hero_cover')} />
              ) : // <HeroImage src='../images/hero-jet.png' />
              null}
            </Section>
          </Wrapper>
          <Pricing>
            <PricingText>Price</PricingText>
            <PricingPrice>
              {detail('display_price') !== undefined &&
              detail('display_price') !== '' &&
              detail('display_price') !== null
                ? detail('display_price')
                : detail('price')}
            </PricingPrice>
            <Button
              as={'button'}
              data-gtm='offer form'
              primary
              onClick={offerClick}>
              Make an Offer
            </Button>
          </Pricing>
        </Hero>
        <HeroMenu>
          <HeroMenuLinks>
            {detail('matterport') ? (
              <VirtualTourLink
                virtualTourLink={detail('matterport')}
                lightbox={props.lightbox}
                setLightbox={props.setLightbox}
                setLightboxSrc={props.setLightboxSrc}
              />
            ) : null}

            {detail('brochure_link') ? (
              <BrochureLink
                brochureLink={detail('brochure_link')}
                lightbox={props.lightbox}
                setLightbox={props.setLightbox}
                setLightboxSrc={props.setLightboxSrc}
              />
            ) : null}

            {detail('vimeo_video_id') ? (
              <VideoLink
                videoID={detail('vimeo_video_id')}
                lightbox={props.lightbox}
                setLightbox={props.setLightbox}
                setLightboxSrc={props.setLightboxSrc}
              />
            ) : null}

            {props.lightboxGallerySrc.length !== 0 ? (
              <GalleryLink
                lightboxGallery={props.lightboxGallery}
                setLightboxGallery={props.setLightboxGallery}
              />
            ) : null}
          </HeroMenuLinks>
          <PlaneGallery
            details={details}
            lightboxGallery={props.lightboxGallery}
            setLightboxGallery={props.setLightboxGallery}
            setLightboxGallerySrc={props.setLightboxGallerySrc}
          />
        </HeroMenu>
      </Heros>
    </Observer>
  );
}

export default PlaneHero;

function VirtualTourLink(props) {
  return (
    <HeroMenuLink
      data-gtm='virtual_tour'
      onClick={(e) => {
        e.preventDefault();
        props.setLightbox(!props.lightbox);
        props.setLightboxSrc(
          <iframe
            title='Virtual Tour'
            width='1920px'
            height='1080px'
            src={props.virtualTourLink}
            style={{ border: 'none', background: 'black' }}
          />
        );
      }}
      href={props.virtualTourLink}>
      <HeroMenuLinkIcon>
        <VirtualTour />
      </HeroMenuLinkIcon>
      <HeroMenuLinkText>Virtual Tour</HeroMenuLinkText>
    </HeroMenuLink>
  );
}

function VideoLink(props) {
  return (
    <HeroMenuLink
      data-gtm='video'
      onClick={(e) => {
        e.preventDefault();
        props.setLightbox(!props.lightbox);
        props.setLightboxSrc(
          <iframe
            title='Video'
            src={`https://player.vimeo.com/video/${props.videoID}?title=0&byline=0&portrait=0`}
            frameborder='0'
            width='1920px'
            height='1080px'
            allow='autoplay; fullscreen'
            allowfullscreen></iframe>
        );
      }}>
      <HeroMenuLinkIcon>
        <Video />
      </HeroMenuLinkIcon>
      <HeroMenuLinkText>Video</HeroMenuLinkText>
    </HeroMenuLink>
  );
}

function BrochureLink(props) {
  return (
    <HeroMenuLink
      data-gtm='brochure'
      href={props.brochureLink}
      target='_blank'
      rel='noopener noreferrer'>
      <HeroMenuLinkIcon>
        <Brochure />
      </HeroMenuLinkIcon>
      <HeroMenuLinkText>Brochure</HeroMenuLinkText>
    </HeroMenuLink>
  );
}

function GalleryLink(props) {
  return (
    <HeroMenuLink
      data-gtm='gallery'
      onClick={(e) => {
        e.preventDefault();
        props.setLightboxGallery(!props.lightboxGallery);
      }}
      href='#'>
      <HeroMenuLinkIcon>
        <Gallery />
      </HeroMenuLinkIcon>
      <HeroMenuLinkText>Gallery</HeroMenuLinkText>
    </HeroMenuLink>
  );
}

const Heros = styled.div`
  background: ${colors.alabaster};

  @media ${mediaQuery.lg} {
    min-height: 100vh;
  }
`;

const Hero = styled.header`
  min-height: 75vh;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  background: #c9dae4;
  overflow: hidden;

  @media ${mediaQuery.lg} {
    margin-bottom: 0;
    min-height: calc(100vh - 100px);
  }
`;

const Section = styled(section)`
  display: flex;
  padding: 140px 0;
  background: transparent;
  flex-direction: column;

  @media ${mediaQuery.lg} {
    flex-direction: row;
  }
`;

const HeroBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: ${colors.timberwolf};
`;

const HeroBackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom left;
`;

const HeroContent = styled.div`
  flex: 1 0 auto;
  max-width: 85%;
  position: relative;

  @media ${mediaQuery.lg} {
    padding-left: ${vars.gridGap * 2}px;
    margin-right: ${vars.gridGap}px;
    max-width: 100%;
  }
`;

const HeroTaglines = styled.div`
  display: flex;
  align-items: center;
`;

const HeroTagline = styled.p`
  ${font({
    name: 'realist',
    responsiveSize: '12px, 18px',
    weight: 400,
    color: colors.black,
    letterSpacing: '0.88px',
    transform: 'uppercase',
  })};
  display: flex;
  align-items: center;
  margin: 0;

  & + & {
    margin-left: ${vars.gridGap}px;
  }

  ${(props) =>
    props.serialNumber &&
    css`
      &::before {
        content: 'SN';
        margin-right: ${vars.gridGap}px;
        transform: translateY(-${vars.gridGap / 3}px);
        font-size: 65%;
      }
    `}
`;

const HeroTitle = styled.h1`
  ${font({
    name: 'ivymode',
    responsiveSize: '40px, 92px',
    weight: 200,
    color: colors.black,
    letterSpacing: '0.4px',
  })};
  margin: ${vars.gridGap}px 0;

  @media ${mediaQuery.lg} {
    margin: ${vars.gridGap * 2}px 0;
    transform: translateX(-${vars.gridGap * 4}px);
  }
`;

const HeroText = styled.p`
  ${font({
    name: 'ivymode',
    responsiveSize: '16px, 20px',
    weight: 200,
    color: colors.black,
    letterSpacing: '0.2px',
  })};
  position: relative;
  max-width: 500px;
  margin: 0;

  &::before {
    content: '—';
    margin-right: ${vars.gridGap / 4}px;
  }

  @media ${mediaQuery.lg} {
    margin: 0 -${vars.gridGap * 4}px 0 0;
    &::before {
      position: absolute;
      left: 0;
      transform: translateX(calc(-100% - ${vars.gridGap / 2}px));
    }
  }
`;

const HeroScroll = styled.a`
  display: none;

  @media ${mediaQuery.lg} {
    position: absolute;
    bottom: 0;
    height: 48px;
    width: 48px;
    border-radius: 50px;
    background-color: ${colors.white};
    box-shadow: 0 3px 6px ${rgba(colors.black, 0.15)};
    transform: translateY(200%);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 200ms;

    &:hover {
      box-shadow: 0 5px 10px ${rgba(colors.black, 0.25)};
    }
  }
`;

const HeroScrollIcon = styled(Icon)`
  height: 16px;
  width: 16px;
`;

const HeroImage = styled.img`
  object-fit: contain;
  width: 115%;
  height: 100%;
  margin-left: 20vw;
  margin-top: ${vars.gridGap}px;

  @media ${mediaQuery.lg} {
    margin: auto 0;
    width: 800px;
    transform: translateY(-55px);
  }
`;

const Pricing = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: inset 0 -20px 0 ${colors.alabaster};

  @media ${mediaQuery.lg} {
    left: initial;
    right: ${vars.gridGap * 2.5}px;
    bottom: ${vars.gridGap * 3}px;
    margin: 5vh 0 ${vars.gridGap * 2}px;
    box-shadow: none;
  }
`;

const PricingText = styled.p`
  ${font({
    name: 'realist',
    size: '12px',
    weight: 600,
    color: colors.makara,
    letterSpacing: '1px',
    transform: 'uppercase',
  })};
  margin: 0 0 ${vars.gridGap / 2}px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
`;

const PricingPrice = styled.h4`
  ${font({
    name: 'realist',
    size: '25px',
    weight: 400,
    color: colors.mineShaft,
    letterSpacing: '1px',
  })};
  margin: 0 0 ${vars.gridGap}px;

  @media ${mediaQuery.lg} {
    margin: 0 0 ${vars.gridGap * 2}px;
  }
`;

const HeroMenu = styled.div`
  width: 100%;
  background-color: ${colors.alabaster};
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  padding-top: ${vars.gridGap}px;

  @media ${mediaQuery.lg} {
    padding-top: 0;
    flex-direction: row;
    height: 100px;
    align-items: center;
  }
`;

const HeroMenuLinks = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;

  @media ${mediaQuery.sm} {
    padding: 0 ${vars.gridGap * 2.5}px;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
  }

  @media ${mediaQuery.lg} {
    padding: 0 ${vars.gridGap * 5}px;
    width: 60%;
  }
`;

const HeroMenuLink = styled.a`
  text-decoration: none;
  color: ${colors.makara};
  padding: ${vars.gridGap}px ${vars.gridGap * 2}px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const HeroMenuLinkIcon = styled(Icon)`
  height: 25px;
  width: 25px;
  margin-right: ${vars.gridGap / 2}px;
  fill: currentColor;
`;

const HeroMenuLinkText = styled.p`
  ${font({
    name: 'realist',
    size: '12px',
    weight: 400,
    letterSpacing: '1px',
    transform: 'uppercase',
  })};
  margin: 0;
`;
