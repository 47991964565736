import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import {
  vars,
  mediaQuery,
  colors,
  font,
  Wrapper,
  Section as section,
} from '../../../helpers/styles';

function ContactHero(props) {
  useEffect(() => {
    props.toggleScrolledNav(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Hero>
      <HeroBackground>
        <HeroBackgroundImage src='../images/hero-contact.jpg' />
      </HeroBackground>
      <Wrapper>
        <Section>
          <HeroContent>
            <HeroTitle>Contact Us</HeroTitle>
          </HeroContent>
        </Section>
      </Wrapper>
    </Hero>
  );
}

export default ContactHero;

const Section = styled(section)`
  display: flex;
  background: transparent;
  padding: 140px 0;
`;

const Hero = styled.header`
  min-height: 30vh;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: flex-end;

  @media ${mediaQuery.lg} {
    min-height: 45vh;
  }
`;

const HeroBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: ${colors.timberwolf};
`;

const HeroBackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
`;

const HeroContent = styled.div`
  flex: 1 0 auto;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const HeroTitle = styled.h1`
  ${font({
    name: 'ivymode',
    responsiveSize: '40px, 57px',
    weight: 200,
    color: colors.black,
    letterSpacing: '0.4px',
  })};
  margin: ${vars.gridGap}px 0;
`;
