import React from 'react';
import styled from 'styled-components/macro';
import {
  vars,
  colors,
  font,
  Wrapper,
  Section as section,
  SectionTitle as sectionTitle,
  SectionText as sectionText
} from '../../../helpers/styles';

function ServicesServingYou(props) {
  return (
    <Section ref={props.servingRef}>
      <Wrapper>
        <Content>
          <SectionTitle>Serving You</SectionTitle>
          <Feature>
            <FeatureText>
              Since our founding, we’ve remained focused on a single line of
              business:  exclusively representing clients seeking to buy, sell,
              and trade new and preowned business aircraft. We specialize in
              providing Aircraft Brokerage, Acquisition, and Advisory services.
            </FeatureText>
          </Feature>
          <SectionText>
            Avpro receives a sales commission only if a transaction is completed
            to your satisfaction. Our goal is to earn a client for life, helping
            you minimize downside risk and capture compelling opportunities on
            the market.
          </SectionText>
        </Content>
      </Wrapper>
    </Section>
  );
}

export default ServicesServingYou;

const Section = styled(section)`
  background: ${colors.alabaster};
`;

const Content = styled.div``;

const SectionTitle = styled(sectionTitle)`
  color: ${colors.bermudaGray};
`;

const Feature = styled.div`
  border-top: 1px solid ${colors.bermudaGray};
  border-bottom: 1px solid ${colors.bermudaGray};
  padding: ${vars.gridGap * 3}px 0;
`;

const FeatureText = styled.p`
  ${font({
    name: 'ivymode',
    responsiveSize: '24px, 32px',
    weight: 200,
    color: colors.mineShaft,
    lineHeight: 1.5
  })};
  margin: 0;

  & + & {
    margin-top: ${vars.gridGap * 2}px;
  }
`;

const SectionText = styled(sectionText)`
  font-weight: 400;
  margin: ${vars.gridGap * 2}px 0 0;
`;
