import React from 'react';
import styled from 'styled-components/macro';
import {
  vars,
  colors,
  Wrapper,
  mediaQuery,
  Section as section,
  SectionHeader as sectionHeader,
  SectionTitle as sectionTitle,
  SectionText
} from '../../../helpers/styles';

function ServicesMarketAnalysis(props) {
  return (
    <Section>
      <Wrapper>
        <Content>
          <SectionHeader>Expertise</SectionHeader>
          <SectionTitle>Market Research &amp; Analysis</SectionTitle>
          <FeatureGrid>
            <GridBox>
              <GridText>
                We maintain the most sophisticated and technology-driven Market
                Intelligence Engine in the industry.
              </GridText>
              <GridText>
                Buyers, sellers, brokers, Original Equipment manufacturers,
                financial institutions, and appraisers contact Avpro every day
                to establish market pricing.
              </GridText>
            </GridBox>
            <FeatureDivider />
            <GridText>
              Our dedicated analysts and researchers continuously anticipate
              changes in the market.
            </GridText>
          </FeatureGrid>
        </Content>
      </Wrapper>
    </Section>
  );
}

export default ServicesMarketAnalysis;

const Section = styled(section)`
  background: ${colors.alabaster};
`;

const Content = styled.div``;

const SectionHeader = styled(sectionHeader)``;

const SectionTitle = styled(sectionTitle)`
  color: ${colors.bermudaGray};
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${vars.gridGap * 2}px;
  border-top: 1px solid ${colors.alto};
  border-bottom: 1px solid ${colors.alto};
  padding: ${vars.gridGap * 3}px 0;

  @media ${mediaQuery.md} {
    grid-template-columns: 1fr 1px 1fr;
  }
`;

const FeatureDivider = styled.div`
  display: none;

  @media ${mediaQuery.md} {
    display: block;
    width: 1px;
    height: 100%;
    box-shadow: 0 16px 0 ${colors.alto}, 0 -16px 0 ${colors.alto};
    background-color: ${colors.alto};
  }
`;

const GridBox = styled.div``;

const GridText = styled(SectionText)`
  font-weight: 400;
  margin: 0;

  & + & {
    margin-top: ${vars.gridGap}px;
  }
`;
