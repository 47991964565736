import React from 'react';
import styled from 'styled-components/macro';
import { colors, font, mediaQuery } from '../../../helpers/styles';

class PlaneFlightRange extends React.Component {
  render() {
    const range = this.props.range;
    if (range >= 4500) {
      const min = 3000;
      const max = 7500;
      const rangePercentage = ((range - min) / (max - min)) * 100;
      return (
        <Box>
          <Scrubber rangePercentage={rangePercentage} rangeValue={range} />
          <svg viewBox='0 0 888 180'>
            <rect x='810' y='105' width='59' height='30' fill='#f8f8f8' />
            <rect x='564' y='105' width='59' height='30' fill='#f8f8f8' />
            <rect x='312' y='105' width='59' height='30' fill='#f8f8f8' />
            <rect x='90' y='105' width='59' height='30' fill='#f8f8f8' />
            <path
              fill='none'
              stroke='#dbdbdb'
              d='M1.9 145.09L887.9 145.09'
              data-name='Line 69'
            />
            <path
              fill='none'
              stroke='#dbdbdb'
              d='M2.4 46.59L2.4 145.59'
              data-name='Line 70'
            />
            <g id='chicago-paris'>
              <text
                fontFamily='RealistWide-Regular, RealistWide'
                fontSize='18'
                transform='translate(98 126.12)'>
                3605
              </text>
              <text
                fill='#8c8070'
                fontFamily='RealistWide-ExtraBold, RealistWide'
                fontSize='10'
                fontWeight='800'
                letterSpacing='.1em'
                transform='translate(68 172.12)'>
                CHICAGO-PARIS
              </text>
              <path
                fill='none'
                stroke='#dbdbdb'
                d='M119 46.59L119 105.69'
                data-name='Line 72'
              />
              <path
                fill='none'
                stroke='#dbdbdb'
                d='M119 135.09L119 156.09'
                data-name='Line 72'
              />
            </g>
            <g id='la-dubai'>
              <path
                fill='none'
                stroke='#dbdbdb'
                d='M836 46.59L836 105.09'
                data-name='Line 74'
              />
              <path
                fill='none'
                stroke='#dbdbdb'
                d='M836 135.09L836 155.89'
                data-name='Line 74'
              />
              <text
                fontFamily='RealistWide-Regular, RealistWide'
                fontSize='18'
                transform='translate(818.06 126.12)'>
                7240
              </text>
              <text
                fill='#8c8070'
                fontFamily='RealistWide-ExtraBold, RealistWide'
                fontSize='10'
                fontWeight='800'
                letterSpacing='.112em'
                transform='translate(808.51 172.12)'>
                LA-DUBAI
              </text>
            </g>
            <g id='nyc-beijing'>
              <text
                fontFamily='RealistWide-Regular, RealistWide'
                fontSize='18'
                transform='translate(572 126.12)'>
                6015
              </text>
              <text
                fill='#8c8070'
                fontFamily='RealistWide-ExtraBold, RealistWide'
                fontSize='10'
                fontWeight='800'
                letterSpacing='.088em'
                transform='translate(553 172.12)'>
                NYC–BEIJING
              </text>
              <path
                fill='none'
                stroke='#dbdbdb'
                d='M594.5 46.59L594.5 105.09'
                data-name='Line 75'
              />
              <path
                fill='none'
                stroke='#dbdbdb'
                d='M594.5 135.09L594.5 155.89'
                data-name='Line 75'
              />
            </g>
            <g id='la-london'>
              <text
                fontFamily='RealistWide-Regular, RealistWide'
                fontSize='18'
                transform='translate(320 126.12)'>
                4735
              </text>
              <text
                fill='#8c8070'
                fontFamily='RealistWide-ExtraBold, RealistWide'
                fontSize='10'
                fontWeight='800'
                letterSpacing='.112em'
                transform='translate(306.5 172.12)'>
                LA-LONDON
              </text>
              <path
                fill='none'
                stroke='#dbdbdb'
                d='M342.5 135.09L342.5 155.89'
                data-name='Line 73'
              />
              <path
                fill='none'
                stroke='#dbdbdb'
                d='M342.5 46.59L342.5 105.09'
                data-name='Line 73'
              />
            </g>
            <g>
              <rect
                x='1'
                y='64'
                width={`${rangePercentage}%`}
                height='5'
                fill='#34109b'
              />
              <rect x='0' y='54' width='108' height='20' fill='#F8F8F8' />
              <rect x='108' y='64' width='764.32' height='5' fill='none' />
            </g>
            <path
              fill='none'
              stroke='#dbdbdb'
              d='M2.4 46.59L2.4 145.59'
              data-name='Line 70'
            />
            <path
              fill='#34109b'
              d='M4 98.09l-4-3c1.1-1.3 29-31 109-31v5c-77.5 0-104.7 28.7-105 29z'
              data-name='Path 12'
            />
          </svg>
        </Box>
      );
    } else {
      const min = -550;
      const max = 5000;
      const rangePercentage = ((range - min) / (max - min)) * 100;
      return (
        <Box>
          <Scrubber rangePercentage={rangePercentage} rangeValue={range} />
          <svg viewBox='0 0 888 180'>
            <rect x='634' y='105' width='59' height='30' fill='#f8f8f8' />
            <rect x='539' y='105' width='59' height='30' fill='#f8f8f8' />
            <rect x='369.5' y='105' width='59' height='30' fill='#f8f8f8' />
            <rect x='121' y='105' width='45.5' height='30' fill='#f8f8f8' />
            <path
              fill='none'
              stroke='#dbdbdb'
              d='M1.9 145.09L887.9 145.09'
              data-name='Line 69'
            />
            <path
              fill='none'
              stroke='#dbdbdb'
              d='M2.4 46.59L2.4 145.59'
              data-name='Line 70'
            />
            <g id='dc-boston'>
              <text
                fontFamily='RealistWide-Regular, RealistWide'
                fontSize='18'
                transform='translate(129 126.12)'>
                355
              </text>
              <text
                fill='#8c8070'
                fontFamily='RealistWide-ExtraBold, RealistWide'
                fontSize='10'
                fontWeight='800'
                letterSpacing='.1em'
                transform='translate(107 172.12)'>
                DC-BOSTON
              </text>
              <path
                fill='none'
                stroke='#dbdbdb'
                d='M144 46.59L144 105.69'
                data-name='Line 72'
              />
              <path
                fill='none'
                stroke='#dbdbdb'
                d='M144 135.09L144 156.09'
                data-name='Line 72'
              />
            </g>
            <g id='chi-paris'>
              <path
                fill='none'
                stroke='#dbdbdb'
                d='M665 46.59L665 105.09'
                data-name='Line 74'
              />
              <path
                fill='none'
                stroke='#dbdbdb'
                d='M665 135.09L665 155.89'
                data-name='Line 74'
              />
              <text
                fontFamily='RealistWide-Regular, RealistWide'
                fontSize='18'
                transform='translate(643 126.12)'>
                3610
              </text>
              <text
                fill='#8c8070'
                fontFamily='RealistWide-ExtraBold, RealistWide'
                fontSize='10'
                fontWeight='800'
                letterSpacing='.112em'
                transform='translate(634 172.12)'>
                CHI-PARIS
              </text>
            </g>
            <g id='nyc-london'>
              <text
                fontFamily='RealistWide-Regular, RealistWide'
                fontSize='18'
                transform='translate(545 126.12)'>
                3000
              </text>
              <text
                fill='#8c8070'
                fontFamily='RealistWide-ExtraBold, RealistWide'
                fontSize='10'
                fontWeight='800'
                letterSpacing='.088em'
                transform='translate(524 172.12)'>
                NYC–LONDON
              </text>
              <path
                fill='none'
                stroke='#dbdbdb'
                d='M568 46.59L568 105.09'
                data-name='Line 75'
              />
              <path
                fill='none'
                stroke='#dbdbdb'
                d='M568 135.09L568 155.89'
                data-name='Line 75'
              />
            </g>
            <g id='la-nyc'>
              <text
                fontFamily='RealistWide-Regular, RealistWide'
                fontSize='18'
                transform='translate(379 126.12)'>
                1945
              </text>
              <text
                fill='#8c8070'
                fontFamily='RealistWide-ExtraBold, RealistWide'
                fontSize='10'
                fontWeight='800'
                letterSpacing='.112em'
                transform='translate(375 172.12)'>
                LA-NYC
              </text>
              <path
                fill='none'
                stroke='#dbdbdb'
                d='M398.5 135.09L398.5 155.89'
                data-name='Line 73'
              />
              <path
                fill='none'
                stroke='#dbdbdb'
                d='M398.5 46.59L398.5 105.09'
                data-name='Line 73'
              />
            </g>
            <g>
              <rect
                x='1'
                y='64'
                width={`${rangePercentage - 2}%`}
                height='5'
                fill='#34109b'
              />
              <rect x='0' y='54' width='108' height='20' fill='#F8F8F8' />
              <rect x='108' y='64' width='764.32' height='5' fill='none' />
            </g>
            <path
              fill='none'
              stroke='#dbdbdb'
              d='M2.4 46.59L2.4 145.59'
              data-name='Line 70'
            />
            <path
              fill='#34109b'
              d='M4 98.09l-4-3c1.1-1.3 29-31 109-31v5c-77.5 0-104.7 28.7-105 29z'
              data-name='Path 12'
            />
          </svg>
        </Box>
      );
    }
  }
}

export default PlaneFlightRange;

const Scrubber = props => (
  <Scrub rangePercentage={props.rangePercentage}>
    <Range>{props.rangeValue} NM</Range>
    <ArrowHead>
      <svg viewBox='0 0 47.4 24.8'>
        <path
          d='M32.5,12.4,2,3.9v17l30.5-8.5M3.1,0,33.6,8.5l13.8,3.9L33.6,16.3,3.1,24.8Z'
          fill='#f8f8f8'
        />
        <path
          d='M0,23.5V1.3L2.5,2,33,10.5l6.9,1.9L33,14.3,2.5,22.8Z'
          fill='#34109b'
        />
      </svg>
    </ArrowHead>
  </Scrub>
);

const Box = styled.div`
  width: 100%;
  height: auto;
  position: relative;

  svg {
    width: 100%;
    height: auto;
    position: relative;
  }
`;

const Scrub = styled.div`
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  left: ${props =>
    props.rangePercentage ? `calc(${props.rangePercentage}% )` : 0};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 79%;
  width: 8%;
  z-index: 2;

  &::after {
    display: block;
    height: 75%;
    width: 5%;
    background: ${colors.mineShaft};
    content: '';
    position: absolute;
    z-index: -1;
    bottom: 0;
  }
`;

const Range = styled.p`
  ${font({
    name: 'realist',
    size: '1.7vw',
    weight: 400,
    color: colors.black
  })};
  white-space: nowrap;
  margin: 0;
  position: absolute;
  top: 0;

  @media ${mediaQuery.xl} {
    font-size: 23px;
  }
`;

const ArrowHead = styled.div`
  height: 20%;
  width: 70%;
  position: absolute;
  top: 36%;
  display: flex;
`;
