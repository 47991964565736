export function checkMediaQueryListener(size) {
  let breakpoint;

  // Get sizes based on CSS breakpoints
  switch (size) {
    case 'xs':
      breakpoint = 320;
      break;
    case 'sm':
      breakpoint = 550;
      break;
    case 'md':
      breakpoint = 768;
      break;
    case 'lg':
      breakpoint = 1024;
      break;
    case 'xl':
      breakpoint = 1440;
      break;
    // if not using a CSS breakpoint, use parameter for size
    default:
      breakpoint = size;
  }

  return window.matchMedia(`(min-width: ${breakpoint}px)`);
}
